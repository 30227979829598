import React, { FC, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Chip } from '@mui/material';
import { Option, Select } from '@mui/joy';

import { IUserLinkedAccountModel } from '../../../apis/types';
import { apiGetLinkedScammers, apiUpdateM2ELocked } from '../../../apis/users';
import DataTable from '../../../components/datatable/DataTable';

interface ILinkedAccountTableProps {
  id: string | undefined;
}

const LinkedAccountsTable: FC<ILinkedAccountTableProps> = ({ id }) => {
  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserLinkedAccountModel>>();
  }, []);

  const { data, isLoading, isPlaceholderData, refetch } = useQuery({
    queryKey: ['linkedAccounts', id],
    queryFn: async () => {
      if (id) {
        const linkedAccounts = await apiGetLinkedScammers(id);
        return {
          rows: linkedAccounts.data,
          total: linkedAccounts.data?.length ?? 0,
        };
      }
    },
    placeholderData: keepPreviousData,
  });

  const updateM2ELocked = async (
    id: string | undefined,
    value: string | null,
  ) => {
    await apiUpdateM2ELocked(id, value);
    refetch();
  };

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('wallet_address', {
        header: 'Wallet Address',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('username', {
        header: 'Username',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('telegram_id', {
        header: 'TG ID',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('l2e_xp', {
        header: 'L2E XP',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.accessor('m2e_xp', {
        header: 'M2E XP',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.accessor('referral_xp', {
        header: 'Referral Xp',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.accessor('total_xp', {
        header: 'Total Xp',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.display({
        header: 'M2E Lock',
        cell: (info) => (
          <Select
            value={info.row.original.m2e_locked}
            onChange={(e, value) =>
              updateM2ELocked(info.row.original._id, value)
            }
          >
            <Option value={'auto'}>Auto</Option>
            <Option value={'unlocked'}>Unlocked</Option>
            <Option value={'locked'}>locked</Option>
          </Select>
        ),
      }),
      helper.accessor('disabled', {
        header: 'Status',
        cell: (info) => (info.getValue() ? 'Disabled' : 'Active'),
      }),
      helper.accessor('verified', {
        header: 'Verify Status',
        enableColumnFilter: false,
        cell: (info) => {
          return info.getValue() ? (
            <Chip label="Verified" variant="outlined" color="success" />
          ) : (
            <Chip label="Not Verified" variant="outlined" />
          );
        },
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableColumnFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
  });

  return (
    <DataTable
      table={table}
      loading={isLoading || isPlaceholderData}
      pagination={false}
    />
  );
};

export default LinkedAccountsTable;
