import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/react';

import './configs/axios';
import './index.css';

if (
  window.location.hostname !== 'localhost' &&
  (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'development' ||
    process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production')
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          `${process.env.REACT_APP_API_SERVER}/api/common/v1/file/upload`,
        ],
        networkCaptureBodies: true,
        networkRequestHeaders: ['x-access-token'],
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/uco\.network/],
    replaysSessionSampleRate: process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE
      ? Number(process.env.REACT_APP_SENTRY_SESSION_SAMPLE_RATE)
      : 1,
    replaysOnErrorSampleRate: process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE
      ? Number(process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE)
      : 1,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
