import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  FormControl,
  IconButton,
} from '@mui/joy';

import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { PlusCircleIcon, EditIcon, TrashIcon } from 'lucide-react';
import DataTable from '../../components/datatable/DataTable';
import CreatePageDialog from './CreatePageDialog';
import EditPageDialog from './EditPageDialog';
import CreateQuestionsDialog from './CreateQuestionsDialog';
import EditQuestionsDialog from './EditQuestionsDialog';
import { IQuestionModel, IPageModel, IAnswerModel } from '../../apis/types';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import { apiPostLesson, apiPutLesson } from '../../apis/lessons';
import { apiUploadImage } from '../../apis/utils';
import CustomInput from '../../components/forms/CustomInput';

interface ICreateLessonDialogProps {
  open?: boolean;
  chapter?: string | undefined;
  onClose: () => void;
  onPositive: () => void;
  onNegative: () => void;
}

const CreateLessonDialog: FC<ICreateLessonDialogProps> = ({
  open = false,
  chapter,
  onClose,
  onPositive,
  onNegative,
}) => {
  const [title, setTitle] = useState<string>('');
  const [pages, setPages] = useState<Array<IPageModel>>([]);
  const [questions, setQuestions] = useState<Array<IQuestionModel>>([]);
  const [editPage, setEditPage] = useState<IPageModel | null>(null);
  const [editQuestion, setEditQuestion] = useState<IQuestionModel | null>(null);
  const [validated, setValidated] = useState<boolean>(false);

  const [pageModals, setPageModals] = useState<any>({
    create: false,
    edit: {
      open: false,
      pageId: null,
    },
    remove: {
      open: false,
      pageId: null,
    },
  });

  const [questionModals, setQuestionModals] = useState<any>({
    create: false,
    edit: {
      open: false,
      questionId: null,
    },
    remove: {
      open: false,
      questionId: null,
    },
  });

  // const

  const openCreatePageDialog = useCallback(() => {
    setPageModals({
      ...pageModals,
      create: true,
    });
  }, [setPageModals]);

  const closeCreatePageDialog = useCallback(() => {
    setPageModals({
      ...pageModals,
      create: false,
    });
  }, [setPageModals]);

  const createNewPage = useCallback(
    async (title: string, content: string, image: File | null) => {
      if (image) {
        const formData = new FormData();
        formData.append('files', image);
        const images = await apiUploadImage(formData);

        setPages([
          ...pages,
          {
            _id: Math.floor(Math.random() * 100000).toString(),
            title,
            content,
            image: images.data[0],
          },
        ]);
      } else {
        setPages([
          ...pages,
          {
            _id: Math.floor(Math.random() * 100000).toString(),
            title,
            content,
          },
        ]);
      }

      setPageModals({
        ...pageModals,
        create: false,
      });
    },
    [setPageModals, setPages, pages],
  );

  const openEditPageDialog = (pageId: string | undefined) => {
    setPageModals({
      ...pageModals,
      edit: {
        open: true,
        pageId: pageId,
      },
    });
  };

  const closeEditPageDialog = useCallback(() => {
    setPageModals({
      ...pageModals,
      edit: {
        open: false,
        pageId: null,
      },
    });
  }, [setPageModals]);

  const updatePage = useCallback(
    async (_id: string, title: string, content: string, image: File | null) => {
      const index = pages.findIndex((value) => {
        if (value._id === _id) return true;
        return false;
      });

      let updatePage = pages[index];

      if (image) {
        const formData = new FormData();
        formData.append('files', image);
        const images = await apiUploadImage(formData);

        updatePage = {
          _id,
          title,
          content,
          image: images.data[0],
        };
      } else {
        updatePage = {
          _id,
          title,
          content,
          image: pages[index].image,
        };
      }

      if (index >= 0) {
        const temp: IPageModel[] = [...pages];
        temp.splice(index, 1);
        setPages([...temp, updatePage]);
      }

      setPageModals({
        ...pageModals,
        edit: {
          open: false,
          pageId: null,
        },
      });
    },
    [setPageModals, setPages, pages],
  );

  const closePageConfirmationDialog = () => {
    setPageModals({
      ...pageModals,
      remove: {
        open: false,
        pageId: null,
      },
    });
  };

  const openPageConfirmationDialog = (pageId: string | undefined) => {
    setPageModals({
      ...pageModals,
      remove: {
        open: true,
        pageId,
      },
    });
  };

  const removePage = () => {
    const index = pages.findIndex((value) => {
      if (value._id === pageModals.remove.pageId) return true;
      return false;
    });

    if (index >= 0) {
      const temp: IPageModel[] = [...pages];
      temp.splice(index, 1);
      setPages(temp);
    }
    closePageConfirmationDialog();
  };

  const closeQuestionConfirmationDialog = () => {
    setQuestionModals({
      ...questionModals,
      remove: {
        open: false,
        questionId: null,
      },
    });
  };

  const openQuestionConfirmationDialog = (questionId: string | undefined) => {
    setQuestionModals({
      ...questionModals,
      remove: {
        open: true,
        questionId,
      },
    });
  };

  const removeQuestion = () => {
    const index = questions.findIndex((value) => {
      if (value._id === questionModals.remove.questionId) return true;
      return false;
    });

    if (index >= 0) {
      const temp: IQuestionModel[] = [...questions];
      temp.splice(index, 1);
      setQuestions(temp);
    }
    closeQuestionConfirmationDialog();
  };

  const openCreateQuestionDialog = () => {
    setQuestionModals({
      ...questionModals,
      create: true,
    });
  };

  const closeCreateQuestionDialog = useCallback(() => {
    setQuestionModals({
      ...questionModals,
      create: false,
    });
  }, [setQuestionModals]);

  const createNewQuestion = (
    title: string,
    xp: number,
    answers: IAnswerModel[],
  ) => {
    setQuestions([
      ...questions,
      {
        _id: Math.floor(Math.random() * 100000).toString(),
        question: title,
        answers,
        xp,
      },
    ]);

    setQuestionModals({
      ...questionModals,
      create: false,
    });
  };

  const openEditQuestionDialog = (questionId: string | undefined) => {
    setQuestionModals({
      ...questionModals,
      edit: {
        open: true,
        questionId,
      },
    });
  };

  const closeEditQuestionDialog = useCallback(() => {
    setQuestionModals({
      ...questionModals,
      edit: {
        open: false,
        questionId: null,
      },
    });
  }, [setQuestionModals]);

  const updateQuestion = useCallback(
    async (_id: string, title: string, xp: number, answers: IAnswerModel[]) => {
      const index = questions.findIndex((value) => {
        if (value._id === _id) return true;
        return false;
      });

      const updateQuestions = (questions[index] = {
        _id,
        question: title,
        answers,
        xp,
      });

      if (index >= 0) {
        const temp: IQuestionModel[] = [...questions];
        temp.splice(index, 1);
        setQuestions([...temp, updateQuestions]);
      }

      closeEditQuestionDialog();
    },
    [setQuestions, questions],
  );

  const createLesson = async () => {
    const response = await apiPostLesson(chapter || '', title);
    const savePage: IPageModel[] = [];
    const saveQuestion: IQuestionModel[] = [];
    if (response.data) {
      pages.forEach((item) => {
        savePage.push({
          title: item.title,
          content: item.content,
          image: item.image,
        });
      });

      questions.forEach((item) => {
        saveQuestion.push({
          question: item.question,
          xp: item.xp,
          answers: item.answers,
        });
      });

      const data = {
        title: title || '',
        pages: savePage,
        questions: saveQuestion,
        _id: response.data,
      };

      await apiPutLesson(data);
      setPages([]);
      setQuestions([]);
      setTitle('');
    }
    onPositive();
  };

  useEffect(() => {
    if (questionModals.edit.open) {
      const index = questions.findIndex((value) => {
        if (value._id === questionModals.edit.questionId) return true;
        return false;
      });
      setEditQuestion({ ...questions[index] });
    }
  }, [questionModals]);

  useEffect(() => {
    if (pageModals.edit.open) {
      const index = pages.findIndex((value) => {
        if (value._id === pageModals.edit.pageId) return true;
        return false;
      });
      setEditPage({ ...pages[index] });
    }
  }, [pageModals]);

  const pageHelper = useMemo(() => {
    return createColumnHelper<Partial<IPageModel>>();
  }, []);

  const questionHelper = useMemo(() => {
    return createColumnHelper<Partial<IQuestionModel>>();
  }, []);

  const pageColumns = useMemo(() => {
    return [
      pageHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),
      pageHelper.accessor('title', {
        header: 'Page Title',
        cell: (info) => info.getValue(),
        size: 400,
        minSize: 400,
        maxSize: 400,
      }),
      pageHelper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                openEditPageDialog(info.row.original._id);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openPageConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [pageHelper]);

  const questionColumns = useMemo(() => {
    return [
      questionHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),
      questionHelper.accessor('question', {
        header: 'Question',
        cell: (info) => info.getValue(),
        size: 350,
        minSize: 350,
        maxSize: 350,
      }),
      questionHelper.accessor('xp', {
        header: 'XP',
        cell: (info) => info.getValue(),
      }),
      questionHelper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                openEditQuestionDialog(info.row.original._id);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openQuestionConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [pageHelper]);

  const pageTable = useReactTable({
    data: pages,
    enableFilters: false,
    columns: pageColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  const questionTable = useReactTable({
    data: questions,
    enableFilters: false,
    columns: questionColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <ModalDialog minWidth={800} maxWidth={1000}>
          <ModalClose />
          <DialogTitle>Create Lesson</DialogTitle>
          <DialogContent>
            <Box>
              <FormControl>
                <CustomInput
                  validation={validated}
                  required
                  title="Lesson Title"
                  value={title}
                  placeholder="Input your lesson title"
                  onChange={(e) => {
                    setTitle(e);
                  }}
                />
              </FormControl>
            </Box>
            <Box mt={4}>
              <Stack flexDirection={'column'} justifyContent={'space-between'}>
                <Box>
                  <Stack
                    flexDirection={'row'}
                    gap={2}
                    alignItems={'center'}
                    mt={2}
                    ml={2}
                  >
                    <DialogTitle>Pages</DialogTitle>
                    <IconButton onClick={openCreatePageDialog}>
                      <PlusCircleIcon />
                    </IconButton>
                  </Stack>
                  <Box mt={1}>
                    <DataTable table={pageTable} pagination={false} />
                  </Box>
                </Box>
                <Box>
                  <Stack
                    flexDirection={'row'}
                    gap={2}
                    alignItems={'center'}
                    mt={2}
                    ml={2}
                  >
                    <DialogTitle>Questions</DialogTitle>
                    <IconButton onClick={openCreateQuestionDialog}>
                      <PlusCircleIcon />
                    </IconButton>
                  </Stack>
                  <Box mt={1}>
                    <DataTable table={questionTable} pagination={false} />
                  </Box>
                </Box>
              </Stack>
            </Box>
          </DialogContent>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Button variant="outlined" onClick={onNegative}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setValidated(true);
                  if (title) {
                    createLesson();
                    setValidated(false);
                  }
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Box>
        </ModalDialog>
      </Modal>
      <CreatePageDialog
        open={pageModals.create}
        onClose={closeCreatePageDialog}
        onNegative={closeCreatePageDialog}
        onPositive={createNewPage}
      />
      <EditPageDialog
        open={pageModals.edit.open}
        onClose={closeEditPageDialog}
        onNegative={closeEditPageDialog}
        onPositive={updatePage}
        data={editPage}
      />
      <ConfirmationDialog
        open={pageModals.remove.open}
        title="Remove Page"
        description="Are you sure to delete current page?"
        onPositive={removePage}
        onNegative={closePageConfirmationDialog}
      />

      <CreateQuestionsDialog
        open={questionModals.create}
        onClose={closeCreateQuestionDialog}
        onNegative={closeCreateQuestionDialog}
        onPositive={createNewQuestion}
      />
      <EditQuestionsDialog
        open={questionModals.edit.open}
        onClose={closeEditQuestionDialog}
        onNegative={closeEditQuestionDialog}
        onPositive={updateQuestion}
        data={editQuestion}
      />

      <ConfirmationDialog
        open={questionModals.remove.open}
        title="Remove Question"
        description="Are you sure to delete current question?"
        onPositive={removeQuestion}
        onNegative={closeQuestionConfirmationDialog}
      />
    </>
  );
};

export default CreateLessonDialog;
