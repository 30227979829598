import React, { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { Box, Button, Card, CardContent, Stack, Typography } from '@mui/joy';
import { ScrollIcon } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';

import { Spinner } from '../../components/indicators/Spinner';
import { colors } from '../../configs/theme';
import {
  apiGetPolicies,
  apiGetPolicy,
  apiUpdatePolicy,
} from '../../apis/policies';
import 'react-quill/dist/quill.bubble.css';

const TermsPage: FC = () => {
  const [content, setContent] = useState('');
  const [processing, setProcessing] = useState(false);

  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['policies', 'terms'],
    queryFn: async () => {
      const data = await apiGetPolicies();
      const result = data.find(
        (policy) => policy.name === 'Terms and Conditions',
      );
      if (result && result._id) {
        const details = await apiGetPolicy(result._id);
        if (details) {
          result.content = details.content;
        }
        return result;
      } else {
        return {
          name: 'Terms and Conditions',
          version: '0',
          content: '',
        };
      }
    },
  });

  const handleUpdate = async () => {
    setProcessing(true);
    if (
      await apiUpdatePolicy({
        _id: data?._id,
        name: 'Terms and Conditions',
        content: content,
      })
    ) {
      await refetch();
    }
    setProcessing(false);
  };

  useEffect(() => {
    if (data && data.content) {
      setContent(data.content);
    } else {
      setContent('Edit the terms and conditions content here...');
    }
  }, [data]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <ScrollIcon />
          <Typography level="h3">
            Terms & Conditions{' '}
            {data?.version ? `(v${Number(data.version).toFixed(2)})` : '...'}
          </Typography>
        </Stack>
        <Button loading={processing} onClick={handleUpdate}>
          Update
        </Button>
      </Stack>
      <Box mt={4}>
        <Card>
          <CardContent
            sx={{
              minHeight: 400,
            }}
          >
            {loading ? (
              <Stack justifyContent="center" alignItems="center" height={400}>
                <Spinner color={colors.raisinBlack} />
              </Stack>
            ) : (
              <ReactQuill
                theme="bubble"
                modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['link'],
                    [{ header: 1 }, { header: 2 }],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { list: 'check' },
                    ],
                    [{ script: 'sub' }, { script: 'super' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['clean'],
                  ],
                }}
                formats={[
                  'header',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'blockquote',
                  'list',
                  'bullet',
                  'indent',
                  'link',
                ]}
                value={content}
                onChange={setContent}
              />
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default TermsPage;
