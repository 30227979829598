import React, { FC, useEffect, useState } from 'react';
import { Box, Typography, Input } from '@mui/joy';
import InputTitle from './InputTitle';

interface ITextInputProps {
  title?: string | undefined;
  size?: number;
  titleProps?: any;
  required?: boolean;
  rows?: number;
  value?: any;
  placeholder?: string;
  endIcon?: any;
  bottomTips?: string;
  type?: string;
  align?: string;
  sx?: any;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  validation?: boolean;
  max?: any;
  min?: any;
  onChange?: (value: string) => void;
  onTitleClick?: () => void;
  caps?: boolean;
  readOnly?: boolean;
}

const CustomInput: FC<ITextInputProps> = ({
  title = '',
  size = 12,
  titleProps = {},
  required = false,
  value = null,
  placeholder = '',
  bottomTips = '',
  type = 'text',
  sx = {},
  disabled = false,
  error = false,
  errorMessage = 'This field is required',
  validation = false,
  max = null,
  min = null,
  onChange,
  onTitleClick = () => void 0,
  readOnly = false,
}) => {
  const [currentValue, setValue] = useState(value);
  useEffect(() => {
    setValue(value);
  }, [value]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        ...sx,
      }}
    >
      <InputTitle
        disabled={disabled}
        size={size}
        sx={{ ...titleProps }}
        error={error || (validation && !currentValue)}
        required={required}
        onClick={onTitleClick}
      >
        {title}
      </InputTitle>
      <Input
        readOnly={readOnly}
        error={error || (validation && !currentValue)}
        type={type}
        placeholder={placeholder}
        size="sm"
        onChange={(e) => {
          if (max !== null && min !== null && type === 'number') {
            if (e.target.value >= min && e.target.value <= max) {
              onChange?.(e.target.value);
              setValue(e.target.value);
            }
          } else {
            onChange?.(e.target.value);
            setValue(e.target.value);
          }
        }}
        disabled={disabled}
        value={currentValue ?? ''}
        sx={{
          backgroundColor: 'text.contrast',
          borderRadius: '8px',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '2px solid #EBF15580 !important',
          },
          '&.Mui-disabled ': {
            backgroundColor: '#EEECE780',
            '& input': {
              WebkitTextFillColor: 'text.primary',
            },
          },
          '& .MuiInputBase-root': {
            borderRadius: '8px',
          },
        }}
      />
      {error || (validation === true && !currentValue) ? (
        <Typography component="span" sx={{ fontSize: '12px', color: 'red' }}>
          {errorMessage}
        </Typography>
      ) : (
        <Typography
          component="span"
          sx={{ color: 'text.accent', fontSize: '10px' }}
        >
          {bottomTips}
        </Typography>
      )}
    </Box>
  );
};

export default CustomInput;
