import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface INetwork {
  network: string;
}

const initialState: INetwork = {
  network: 'VeChain',
};

export const networkSlice = createSlice({
  name: 'network',
  initialState: initialState as INetwork,
  reducers: {
    setNetwork(state, action: PayloadAction<INetwork>) {
      return action.payload;
    },
    resetNetwork() {
      return initialState;
    },
  },
});

export const { setNetwork, resetNetwork } = networkSlice.actions;
