import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
} from '@mui/joy';
import React, { FC, useState } from 'react';
import CustomInput from '../../components/forms/CustomInput';

interface ICreateChallengeDialogProps {
  open?: boolean;
  challenge?: number;
  onPositive: (name: string) => void;
  onNegative: () => void;
  onClose: () => void;
}

const CreateChallengeDialog: FC<ICreateChallengeDialogProps> = ({
  open = false,
  challenge = 1,
  onPositive,
  onNegative,
  onClose,
}) => {
  const [name, setName] = useState<string>('');
  const [validated, setValidated] = useState<boolean>(false);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth={320} maxWidth={480}>
        <ModalClose />
        <DialogTitle>Challenge - {challenge + 1}</DialogTitle>
        <DialogContent>
          <CustomInput
            required
            validation={validated}
            title="Challenge Title"
            placeholder="Input your challenge title"
            onChange={(e) => {
              setName(e);
            }}
          />
        </DialogContent>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button variant="outlined" onClick={onNegative}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setValidated(true);
                if (name) {
                  onPositive(name);
                  setValidated(false);
                }
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
export default CreateChallengeDialog;
