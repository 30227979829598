import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IOrganizationModel } from './types';

export const apiGetOrganizations = async ({
  keyword = '',
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
}: {
  keyword?: string | null;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
}) => {
  try {
    const response = await axios.post<{
      tatal_count?: number;
      total_count?: number;
      data: IOrganizationModel[];
    }>('/api/orgs', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(keyword && {
        keyword,
      }),
    });

    return {
      rows: response.data.data,
      total: response.data.tatal_count
        ? response.data.tatal_count
        : response.data.total_count
          ? response.data.total_count
          : 0,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load organizations data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};

export const apiUpdateOrgStatus = async (id: string, status: string) => {
  try {
    await axios.put(`/api/orgs/${id}`, {
      blocked: status === 'blocked',
    });
    enqueueSnackbar('Successfully updated the selected organization', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to updated the selected organization', {
      variant: 'error',
    });
    return false;
  }
};

export const apiDeleteOrganization = async (id: string) => {
  try {
    await axios.delete(`/api/orgs/${id}`);
    enqueueSnackbar('Successfully deleted the selected organization', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected organization', {
      variant: 'error',
    });
    return false;
  }
};
