import React, { FC } from 'react';

import { Typography } from '@mui/joy';

interface IInputTitleProps {
  children: React.ReactNode;
  disabled: boolean;
  size: number;
  sx: any;
  error: boolean;
  required: boolean;
  onClick: () => void;
}
const InputTitle: FC<IInputTitleProps> = ({
  children,
  disabled,
  size = 12,
  sx,
  error = false,
  required = false,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      <Typography
        sx={{
          fontFamliy: 'Manrope',
          fontSize: size,
          fontWeight: 700,
          color: disabled ? 'text.accent' : error ? 'red' : 'text.secondary',
          lineHeight: '24px',
          ...sx,
          // textTransform: 'uppercase',
        }}
      >
        {children}
        {required && (
          <Typography component="span" sx={disabled ? {} : { color: 'red' }}>
            *
          </Typography>
        )}
      </Typography>
    </div>
  );
};

export default InputTitle;
