import { Stack, Typography, Button, IconButton, Input } from '@mui/joy';
import { Layers, EditIcon } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { ILevelModel } from '../../apis/types';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import DataTable from '../../components/datatable/DataTable';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { apiGetLevels, apiUpdateLevelXP } from '../../apis/level';

const LevelsPage = () => {
  const [modal, setModal] = useState<any>({
    edit: false,
    _id: '',
    value: 0,
  });

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['level'],
    queryFn: async () => {
      const result = await apiGetLevels();
      console.log(result);
      return result;
    },
    placeholderData: keepPreviousData,
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<ILevelModel>>();
  }, []);

  const closeEditModal = () => {
    setModal({ ...modal, edit: false });
  };

  const handelUpdate = () => {
    apiUpdateLevelXP(modal._id, modal.value);
    refetch();
    closeEditModal();
  };

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 70,
        enableColumnFilter: false,
      }),
      helper.accessor('name', {
        header: 'Level Name',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      helper.accessor('xp_for_nft_mint', {
        header: 'Minting Cost(XP)',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() =>
                setModal({
                  ...modal,
                  edit: true,
                  _id: info.row.original._id,
                  value: info.row.original.xp_for_nft_mint,
                })
              }
            >
              <EditIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data ?? [],
    enableFilters: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Layers />
          <Typography level="h3">Ambassador Levels</Typography>
        </Stack>
        <Button type="button">Create</Button>
      </Stack>
      <Stack mt={4}>
        <DataTable table={table} pagination={false} loading={isLoading} />
      </Stack>
      <ConfirmationDialog
        open={modal.edit}
        title="XP Required to Mint"
        description={
          <Input
            type="number"
            placeholder="Enter XP required to mint"
            value={modal.value}
            onChange={(e) =>
              setModal({ ...modal, value: Number(e.target.value) })
            }
          />
        }
        onPositive={() => handelUpdate()}
        onNegative={closeEditModal}
      />
    </>
  );
};

export default LevelsPage;
