import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';

const Tabs = ({
  children,
  onTabChangeHandler,
  tab = 0,
}: {
  children: React.ReactNode;
  onTabChangeHandler: (tabId: number) => void;
  tab: number;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tab);
  const childrens: any = React.Children.toArray(children);
  const onTabChange = (tabId: number) => {
    setSelectedTab(tabId);
    onTabChangeHandler(tabId);
  };

  useEffect(() => {
    setSelectedTab(tab);
  }, [tab]);

  return (
    <>
      <Box
        sx={{
          paddingRight: '20px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TabHeader onTabChangeHandler={onTabChange} selectedTab={selectedTab}>
            {childrens[0].props.children}
          </TabHeader>
        </Box>
        <TabBody selectedTab={selectedTab}>
          {childrens[1].props.children}
        </TabBody>
      </Box>
    </>
  );
};

const TabHeader = ({
  children,
  onTabChangeHandler,
  selectedTab,
}: {
  children?: React.ReactNode;
  onTabChangeHandler?: (tabId: number) => void;
  selectedTab?: number;
}) => {
  const onTabChange = (tabId: number) => {
    if (onTabChangeHandler) {
      onTabChangeHandler(tabId);
    }
  };

  return (
    <>
      <Box
        overflow="auto"
        sx={{
          overflowY: 'hidden',
          width: '100%',
          transform: 'rotateX(180deg);',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            transform: 'rotateX(180deg);',
          }}
        >
          {React.Children.map(children, (item: any, index) => {
            return (
              <TabHeaderItem
                selected={index === selectedTab}
                tabId={index}
                key={index}
                onTabChangeHandler={onTabChange}
              >
                {item?.props?.children}
              </TabHeaderItem>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const TabHeaderItem = ({
  tabId,
  children,
  selected,
  onTabChangeHandler,
}: {
  tabId?: number;
  children?: React.ReactNode;
  selected?: boolean;
  onTabChangeHandler?: (tabId: number) => void;
}) => {
  return (
    <Box
      sx={{
        fontSize: '14px',
        fontWeight: '500',
        color: selected ? '#23222D' : '#6D6D6D',
        padding: '8px 40px 0px 0px',
        textAlign: 'center',
        fontFamily: 'Manrope',
        '&:hover': {
          cursor: 'pointer',
          color: '#6D6D6D',
          '& > .tab-border': {
            backgroundColor: '#6D6D6D',
          },
        },
        '&:active': {
          cursor: 'pointer',
          color: 'background.primary',
        },
      }}
      onClick={() => {
        if (onTabChangeHandler) {
          onTabChangeHandler(tabId ?? 0);
        }
      }}
    >
      <Box
        sx={{
          paddingBottom: '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {children}
      </Box>
      {selected && (
        <Box
          className="tab-border"
          sx={{
            backgroundColor: '#23222D',
            height: '2px',
            width: '100%',
            transform: 'translate(0,50%)',
          }}
        ></Box>
      )}
    </Box>
  );
};

const TabBody = ({
  children,
  selectedTab,
}: {
  children: React.ReactNode;
  selectedTab?: number;
}) => {
  return (
    <>
      <Box sx={{ width: '100%' }}>
        {React.Children.map(children, (item: any, index) => {
          return (
            <Tab key={index} hidden={selectedTab !== index}>
              {item?.props?.children}
            </Tab>
          );
        })}
      </Box>
    </>
  );
};

const Tab = ({
  children,
  hidden = false,
}: {
  children: React.ReactNode;
  hidden?: boolean;
}) => {
  return (
    <>
      <Box
        sx={{
          paddingTop: '20px',
          position: 'relative',
          width: '100%',
          display: hidden ? 'none' : 'block',
        }}
      >
        {children}
      </Box>
    </>
  );
};

export { Tab, Tabs, TabHeader, TabHeaderItem, TabBody };
