import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IPlaceState {
  pageIndex: number | null;
  pageSize: number | null;
  onlyMapped: boolean;
}

const initialState: IPlaceState = {
  pageIndex: null,
  pageSize: null,
  onlyMapped: false,
};

export const placeSlice = createSlice({
  name: 'place',
  initialState: initialState as IPlaceState,
  reducers: {
    setPlaceState(state, action: PayloadAction<IPlaceState>) {
      return action.payload;
    },
    resetPlaceState() {
      return initialState;
    },
  },
});

export const { setPlaceState, resetPlaceState } = placeSlice.actions;
