import React from 'react';
import { Chip } from '@mui/joy';

interface IPlaceStatusChipProps {
  status?: string | null | undefined;
}

const variants: { [key: string]: any } = {
  mapped: {
    type: 'success',
    label: 'MAPPED',
  },
  rejected: {
    type: 'danger',
    label: 'REJECTED',
  },
  pending: {
    type: 'primary',
    label: 'PENDING',
  },
  cached: {
    type: 'warning',
    label: 'CACHED',
  },
};

export default class PlaceStatusChip extends React.Component<IPlaceStatusChipProps> {
  constructor(props: IPlaceStatusChipProps) {
    super(props);
  }

  render(): React.ReactNode {
    if (!this.props.status) {
      return null;
    }

    return (
      <Chip
        color={
          variants[this.props.status]
            ? variants[this.props.status].type
            : 'neutral'
        }
      >
        {variants[this.props.status] ? variants[this.props.status].label : ''}
      </Chip>
    );
  }
}
