import React, { FC, useMemo, useState } from 'react';
import { Stack, Typography, Grid, Box } from '@mui/joy';
import StatCard from '../../../components/widgets/StatCard';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { IRaffleVotersModel } from '../../../apis/types';
import DataTable from '../../../components/datatable/DataTable';
import { useQuery } from '@tanstack/react-query';
import { apiGetVoters } from '../../../apis/raffle';
import { useParams } from 'react-router-dom';

const VoterPage: FC = () => {
  const { round } = useParams<{ round: string }>();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isLoading } = useQuery({
    queryKey: ['raffle-detail', round, pagination, sorting],
    queryFn: async () => {
      const page = pagination.pageIndex + 1;
      const limit = pagination.pageSize;
      let sortBy = '';
      let sortOrder = '';

      if (sorting.length > 0) {
        sortBy = sorting[0].id;
        sortOrder = sorting[0].desc ? 'desc' : 'asc';
      }

      const data = await apiGetVoters({
        round: String(round),
        page: page,
        limit: limit,
        sortBy: sortBy,
        sortOrder: sortOrder,
      });
      return data;
    },
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IRaffleVotersModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('wallet_address', {
        header: 'Wallet Address',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('amount', {
        header: 'Votes',
        cell: (info) => info.getValue(),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.data || [],
    rowCount: data?.total_voters ?? 0,
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    state: {
      pagination: pagination,
      sorting: sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography level="h3">Week {round}</Typography>
        </Stack>
      </Stack>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title={'Number Voters'}
              value={data?.total_voters || 0}
              variant="dark"
            />
          </Grid>
          <Grid>
            <StatCard
              title={'Total Votes'}
              value={data?.total_votes || 0}
              variant="dark-blue"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack mt={4}>
        <DataTable table={table} loading={isLoading} />
      </Stack>
    </>
  );
};

export default VoterPage;
