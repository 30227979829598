import React, { FC, useState, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  Stack,
  IconButton,
  Input,
  Checkbox,
  Switch,
  Textarea,
} from '@mui/joy';
import { ThumbsDown, ThumbsUp, Crosshair, Eye } from 'lucide-react';

import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { setPlaceState } from '../../redux/places.slice';
import { IRootState, store } from '../../redux/store';
import {
  apiGetPlaces,
  apiGetPlacesAll,
  apiGetWmos,
  apiUpdatePlace,
  apiBlockAndReject,
  apiVerifyWmo,
} from '../../apis/places';
import { IPlaceModel, IWmoModel, IAddModel } from '../../apis/types';
import { getSortingStateFromQuery } from '../../components/functions';
import StatCard from '../../components/widgets/StatCard';
import DataTable from '../../components/datatable/DataTable';
import {
  TabBody,
  TabHeader,
  TabHeaderItem,
  Tabs,
} from '../../components/tabs/Tab';
import PlaceStatusChip from '../../components/chips/PlaceStatusChip';
import PlaceVerificationChip from '../../components/chips/PlaceVerificationChip';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import { apiGetRewardConstants } from '../../apis/rewards';

const PlacesPage: FC = () => {
  const navigate = useNavigate();
  const place = useSelector((state: IRootState) => state.place);
  const [params, setParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex'))
      ? Number(params.get('pageIndex')) - 1
      : place.pageIndex
        ? place.pageIndex
        : 0,
    pageSize: Number(params.get('pageSize')) || place.pageSize || 100,
  });

  const [sorting, setSorting] = useState<SortingState>(
    getSortingStateFromQuery(params, ['_id', 'name', 'country', 'city', 'xp']),
  );

  const [filters, setFilters] = React.useState<ColumnFiltersState>([]);

  const [countryList, setCountryList] = useState<string[]>([]);
  const [cityList, setCityList] = useState<string[]>([]);
  // const [chapterList, setChapterList] = useState<IChapterModel[]>([]);
  // const [selectedChapter, setSelectedChapter] = useState<string | null>(null);
  const [flag, setFlag] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [addModal, setAddModal] = useState<any>({
    approve: false,
    reject: false,
    block: false,
    _id: '',
    reason: '',
  });
  const [wmoModal, setWmoModal] = useState<any>({
    approve: false,
    reject: false,
    block: false,
    _id: '',
    add_to_crm: true,
    reason: '',
  });

  const typeList = ['company', 'individual'];

  const [onlyShowMapped, setOnlyShowMapped] = useState<boolean>(
    place.onlyMapped ?? false,
  );
  const [onlyAdded, setOnlyAdded] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [totalXp, setTotalXp] = useState(0);

  const handlePlaceUpdate = async (
    id: string | undefined,
    status: string,
    reject_reason: string | undefined,
  ) => {
    await apiUpdatePlace(id, status, reject_reason);
    closeConfirmationAddDialog();
    refetch();
  };

  const handleWmoUpdate = async (
    id: string | undefined,
    status: string,
    add_to_crm: boolean,
    reason: string,
  ) => {
    apiVerifyWmo(id, status, add_to_crm, reason);
    closeConfirmationWMODialog();
    refetchWmo();
  };

  const handleBlock = async (created_by: string | undefined, type: string) => {
    await apiBlockAndReject(created_by);

    if (type === 'add') {
      closeConfirmationAddDialog();
      refetch();
    } else if (type === 'wmo') {
      closeConfirmationWMODialog();
      refetchWmo();
    }
  };

  const handleToggleOnlyMapped = () => {
    setPagination({
      ...pagination,
      pageIndex: 0,
    });

    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });
    setOnlyShowMapped(!onlyShowMapped);
  };

  const closeConfirmationAddDialog = () => {
    setAddModal({
      approve: false,
      reject: false,
      block: false,
      _id: '',
      reason: '',
    });
  };

  const closeConfirmationWMODialog = () => {
    setWmoModal({
      approve: false,
      reject: false,
      block: false,
      _id: '',
      reason: '',
    });
  };

  // const onChangeM2EChapter = async (id: string | any) => {
  //   if (id !== '1') {
  //     const chapter = chapterList.find((c) => c._id === id);

  //     if (chapter) {
  //       await apiPutChapter(id, {
  //         title: chapter.title,
  //         locked: chapter.locked,
  //         open_m2e: true,
  //       } as IChapterModel);
  //       setSelectedChapter(id);
  //     }
  //   } else {
  //     const chapter = chapterList.find((c) => c._id === selectedChapter);

  //     if (chapter) {
  //       await apiPutChapter(chapter._id, {
  //         title: chapter.title,
  //         locked: chapter.locked,
  //         open_m2e: false,
  //       } as IChapterModel);
  //       setSelectedChapter('1');
  //     }
  //   }
  // };

  const tabChange = (value: number) => {
    setCurrentTab(value);
    setFlag(true);
  };

  const { data, isLoading, isPlaceholderData, refetch } = useQuery({
    queryKey: [
      'places',
      params.get('search') ?? '',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
      params.get('filter'),
      onlyShowMapped,
      onlyAdded,
    ],
    queryFn: async () => {
      const pageIndex = Number(params.get('pageIndex')) || place.pageIndex || 1;
      const pageSize = Number(params.get('pageSize')) || place.pageSize || 100;
      const search = params.get('search') || '';
      let sortBy = '';
      let sortOrder = '';
      if (sorting.length > 0) {
        sortBy = sorting[0].id;
        sortOrder = sorting[0].desc ? 'desc' : 'asc';
      }

      let filter = {};

      if (filters.length > 0) {
        filter = {
          field: filters[0].id,
          value: filters[0].value,
        };
      }

      return await apiGetPlaces({
        search,
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,
        filter,
        only_mapped: !onlyShowMapped,
        only_added: onlyAdded,
      });
    },
    placeholderData: keepPreviousData,
  });

  useQuery({
    queryKey: ['place-rewards'],
    queryFn: async () => {
      const response = await apiGetRewardConstants();
      setTotalXp(
        response.m2e_details_xp +
          response.m2e_image_xp +
          response.m2e_phone_xp +
          response.m2e_uco_xp +
          response.m2e_wmo_xp,
      );
    },
  });

  const {
    data: wmoData,
    isLoading: wmoIsLoading,
    isPlaceholderData: isPlaceholderWmoData,
    refetch: refetchWmo,
  } = useQuery({
    queryKey: [
      'wmos',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
      params.get('filter'),
    ],
    queryFn: async () => {
      const pageIndex = Number(params.get('pageIndex')) || place.pageIndex || 1;
      const pageSize = Number(params.get('pageSize')) || place.pageSize || 100;
      let sortBy = '';
      let sortOrder = '';
      if (sorting.length > 0) {
        sortBy = sorting[0].id;
        sortOrder = sorting[0].desc ? 'desc' : 'asc';
      }

      let filter = {};

      if (filters.length > 0) {
        filter = {
          field: filters[0].id,
          value: filters[0].value,
        };
      }

      return await apiGetWmos({
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,
        filter,
      });
    },
    placeholderData: keepPreviousData,
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IPlaceModel>>();
  }, [countryList, cityList]);

  const wmoHelper = useMemo(() => {
    return createColumnHelper<Partial<IWmoModel>>();
  }, []);

  const addHelper = useMemo(() => {
    return createColumnHelper<Partial<IAddModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        size: 110,
        enableColumnFilter: false,
      }),
      helper.accessor('name', {
        header: 'Restaurant Name',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        minSize: 250,
        size: 250,
        maxSize: 250,
      }),
      helper.accessor('country', {
        header: 'Country',
        meta: {
          filterVariant: 'select',
          filterOptions: countryList,
        },
        cell: (info) => info.getValue(),
        minSize: 150,
        size: 150,
        maxSize: 150,
      }),
      helper.accessor('city', {
        header: 'City',
        meta: {
          filterVariant: 'select',
          filterOptions: cityList,
        },
        cell: (info) => info.getValue(),
        minSize: 200,
        size: 200,
        maxSize: 200,
      }),
      helper.accessor('created_username', {
        header: 'Mapped By',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
        size: 150,
      }),
      helper.accessor('verification_status', {
        header: 'Verification',
        enableColumnFilter: false,
        cell: (info) => <PlaceVerificationChip status={info.getValue()} />,
      }),
      helper.accessor('status', {
        header: 'Status',
        enableColumnFilter: false,
        cell: (info) => <PlaceStatusChip status={info.getValue()} />,
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <IconButton
            size="sm"
            color="primary"
            onClick={() => {
              navigate(`detail/${info.row.original._id}`);
            }}
          >
            <Eye size={16} />
          </IconButton>
        ),
        size: 40,
      }),
    ];
  }, [helper]);

  const wmoColumns = useMemo(() => {
    return [
      wmoHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        size: 110,
        enableColumnFilter: false,
      }),
      wmoHelper.accessor('collector_name', {
        header: 'WMO Name',
        cell: (info) => info.getValue(),
        minSize: 250,
        size: 250,
        maxSize: 250,
      }),
      wmoHelper.accessor('collector_email', {
        header: 'Email',
        cell: (info) => info.getValue(),
      }),
      wmoHelper.accessor('collector_phone_number', {
        header: 'Phone Number',
        cell: (info) => info.getValue(),
      }),
      wmoHelper.accessor('type', {
        header: 'Type',
        meta: {
          filterVariant: 'select',
          filterOptions: typeList,
        },
        cell: (info) => info.getValue(),
      }),
      wmoHelper.accessor('count_vehicles', {
        header: 'Vehicles',
        enableColumnFilter: false,
        cell: (info) => info.getValue(),
      }),
      wmoHelper.accessor('mapped_by', {
        header: 'Mapped By',
        cell: (info) => info.getValue(),
        minSize: 200,
        size: 200,
        maxSize: 200,
      }),
      wmoHelper.accessor('status', {
        header: 'Status',
        enableColumnFilter: false,
        cell: (info) => <PlaceVerificationChip status={info.getValue()} />,
      }),
      wmoHelper.display({
        header: 'Actions',
        cell: (info) => {
          return (
            <Box>
              <IconButton
                size="sm"
                color="primary"
                onClick={() => {
                  setWmoModal({
                    ...wmoModal,
                    approve: true,
                    _id: info.row.original._id,
                  });
                }}
              >
                <ThumbsUp size={16} />
              </IconButton>
              <IconButton
                size="sm"
                color="primary"
                onClick={() => {
                  setWmoModal({
                    ...wmoModal,
                    reject: true,
                    _id: info.row.original._id,
                  });
                }}
              >
                <ThumbsDown size={16} />
              </IconButton>
              <IconButton
                size="sm"
                color="primary"
                onClick={() => {
                  setWmoModal({
                    ...wmoModal,
                    block: true,
                    _id: info.row.original.user_id,
                  });
                }}
              >
                <Crosshair size={16} />
              </IconButton>
            </Box>
          );
        },
        minSize: 100,
        size: 100,
        maxSize: 100,
      }),
    ];
  }, [wmoHelper]);

  const addColumns = useMemo(() => {
    return [
      addHelper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        size: 110,
        enableColumnFilter: false,
      }),
      addHelper.accessor('name', {
        header: 'Restaurant Name',
        cell: (info) => info.getValue(),
        enableColumnFilter: false,
      }),
      addHelper.accessor('country', {
        header: 'Address',
        meta: {
          filterVariant: 'select',
          filterOptions: countryList,
        },
        cell: (info) => info.getValue(),
      }),
      addHelper.accessor('created_username', {
        header: 'Mapped By',
        cell: (info) => info.getValue(),
      }),
      addHelper.display({
        header: 'Actions',
        cell: (info) => (
          <Box>
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                setAddModal({ approve: true, _id: info.row.original._id });
              }}
            >
              <ThumbsUp size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                setAddModal({ reject: true, _id: info.row.original._id });
              }}
            >
              <ThumbsDown size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                setAddModal({
                  block: true,
                  _id: info.row.original.created_by,
                });
              }}
            >
              <Crosshair size={16} />
            </IconButton>
          </Box>
        ),
        minSize: 100,
        size: 100,
        maxSize: 100,
      }),
    ];
  }, [addHelper]);

  const placesTable = useReactTable({
    data: data?.rows ?? [],
    enableFilters: true,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: data?.total_filtered_places ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
  });

  const addTable = useReactTable({
    data: data?.rows ?? [],
    enableFilters: true,
    columns: addColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: data?.total_filtered_places ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
  });

  const wmoTable = useReactTable({
    data: wmoData?.rows ?? [],
    enableFilters: true,
    columns: wmoColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    rowCount: wmoData?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
      columnFilters: filters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setFilters,
  });

  useEffect(() => {
    if (sorting) {
      setParams((oldParams) => {
        const newParams = new URLSearchParams(oldParams);
        if (sorting.length === 0) {
          newParams.delete('sortBy');
        } else {
          newParams.set(
            'sortBy',
            sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(','),
          );
        }
        return newParams;
      });
    }
  }, [sorting]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (filters.length === 0) {
        newParams.delete('filter');
      } else {
        newParams.set(
          'filter',
          filters.map((f) => `${f.id},${f.value}`).join(','),
        );
      }
      return newParams;
    });
  }, [filters]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (pagination.pageIndex + 1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });

    store.dispatch(
      setPlaceState({
        onlyMapped: onlyShowMapped,
        pageIndex: pagination?.pageIndex,
        pageSize: pagination?.pageSize,
      }),
    );
  }, [pagination]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (onlyShowMapped) {
        newParams.set('mapped', 'true');
      } else {
        newParams.delete('mapped');
      }
      return newParams;
    });
    store.dispatch(
      setPlaceState({
        pageIndex: pagination?.pageIndex,
        pageSize: pagination?.pageSize,
        onlyMapped: onlyShowMapped,
      }),
    );
  }, [onlyShowMapped]);

  useEffect(() => {
    setSearch('');
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.delete('sortBy');
      newParams.delete('search');
      newParams.delete('pageIndex');
      newParams.delete('pageSize');
      newParams.delete('type');
      newParams.delete('mapped');
      newParams.set('pageIndex', '1');
      newParams.set('pageSize', pagination.pageSize.toString());
      if (currentTab === 0) {
        newParams.set('type', 'places');
      } else if (currentTab === 2) {
        newParams.set('type', 'mappedPlaces');
      } else {
        newParams.set('type', 'wmo');
      }
      return newParams;
    });
    if (flag) {
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab === 2) {
      setOnlyAdded(true);
      setOnlyShowMapped(true);
    } else {
      setOnlyShowMapped(false);
      setOnlyAdded(false);
    }
  }, [currentTab]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (search) {
        newParams.set('search', search);
      } else {
        newParams.delete('search');
      }
      return newParams;
    });
    if (search !== '' && search !== null) {
      setPagination({
        ...pagination,
        pageIndex: 0,
      });
    }
  }, [search]);

  useEffect(() => {
    const fetchPlaces = async () => {
      const places = await apiGetPlacesAll();
      const country = places.map((p) => p.country);
      setCountryList(
        Array.from(new Set(country.filter((item) => item !== null))),
      );
      const city = places.map((p) => p.city);
      setCityList(Array.from(new Set(city.filter((item) => item !== null))));

      // const chapters = await apiGetAllChapters();
      // // setChapterList(chapters);

      // const chapter = chapters.find((c: IChapterModel) => c.open_m2e === true);

      // if (chapter) {
      //   setSelectedChapter(chapter._id);
      // } else {
      //   setSelectedChapter('1');
      // }
    };
    fetchPlaces();

    if (params.get('type') === 'places') {
      setCurrentTab(0);
    } else if (params.get('type') === 'mappedPlaces') {
      setCurrentTab(2);
      setOnlyShowMapped(true);
    } else if (params.get('type') === 'wmo') {
      setCurrentTab(1);
    } else {
      setParams((oldParams) => {
        const newParams = new URLSearchParams(oldParams);
        newParams.set('type', 'places');
        newParams.set('mapped', 'false');
        return newParams;
      });
    }
    if (params.get('mapped') === 'true') {
      setOnlyShowMapped(true);
    }
    setPagination({
      pageIndex: Number(params.get('pageIndex'))
        ? Number(params.get('pageIndex')) - 1
        : 0,
      pageSize: Number(params.get('pageSize')) || 10,
    });
  }, []);

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Places"
              value={data?.total ?? 0}
              variant="dark"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Filtered"
              value={data?.total_filtered_places ?? 0}
              variant="dark-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Mapped"
              value={data?.total_mapped_places ?? 0}
              variant="light"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack mt={3}>
        <Tabs onTabChangeHandler={tabChange} tab={currentTab}>
          <TabHeader>
            <TabHeaderItem>Mapped Restaurants</TabHeaderItem>
            <TabHeaderItem>Mapped WMOs</TabHeaderItem>
            <TabHeaderItem>Added Restaurants</TabHeaderItem>
          </TabHeader>
          <TabBody>
            <>
              <Stack direction="row" gap={4} justifyContent="space-between">
                <Box mt={2}>
                  <Input
                    placeholder="Search Input"
                    value={search}
                    sx={{ width: '300px' }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Box>
                <Box mt={2}>
                  <Stack direction="row" alignItems="center" gap={1}>
                    <Switch
                      checked={onlyShowMapped}
                      onChange={handleToggleOnlyMapped}
                    />
                    <Typography>Show All</Typography>
                  </Stack>
                </Box>
              </Stack>

              <Box mt={2}>
                <DataTable
                  table={placesTable}
                  loading={isLoading || isPlaceholderData}
                  onRowClick={(row: any) => navigate(`detail/${row._id}`)}
                />
              </Box>
            </>
            <>
              <Box mt={2}>
                <Input
                  placeholder="Search Input"
                  value={search}
                  sx={{ width: '300px' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <DataTable
                  table={wmoTable}
                  loading={wmoIsLoading || isPlaceholderWmoData}
                />
              </Box>
            </>
            <>
              <Box mt={2}>
                <Input
                  placeholder="Search Input"
                  value={search}
                  sx={{ width: '300px' }}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Box mt={2}>
                <DataTable
                  table={addTable}
                  loading={isLoading || isPlaceholderData}
                />
              </Box>
            </>
          </TabBody>
        </Tabs>
      </Stack>

      <ConfirmationDialog
        open={addModal.approve}
        title="Approve"
        description="You are about to approve this restaurant and award 100XP."
        onPositive={() => handlePlaceUpdate(addModal._id, 'approved', '')}
        onNegative={closeConfirmationAddDialog}
      />

      <ConfirmationDialog
        open={addModal.block}
        title="Block User"
        description="Are you sure you want to block this user? This will also remove all restaurants added by them."
        onPositive={() => handleBlock(addModal._id, 'add')}
        onNegative={closeConfirmationAddDialog}
      />

      <ConfirmationDialog
        open={addModal.reject}
        title="Reject"
        description={
          <Box>
            <Typography component="p">
              You are about to reject this restaurant.
            </Typography>
            <Stack direction="row" alignItems="center" mt={2}>
              <Textarea
                sx={{ width: '100%' }}
                minRows={4}
                onChange={(event: any) =>
                  setAddModal({ ...addModal, reason: event.target.value })
                }
              />
            </Stack>
          </Box>
        }
        onPositive={() =>
          handlePlaceUpdate(addModal._id, 'disapproved', addModal.reason)
        }
        onNegative={closeConfirmationAddDialog}
      />

      <ConfirmationDialog
        open={wmoModal.approve}
        title="Approve"
        description={
          <Box>
            <Typography component="p">
              {`You are about to reward the user ${totalXp}XP for the details
              provided.`}
            </Typography>
            <Stack direction="row" alignItems="center" mt={2}>
              <Checkbox
                label="Add to CRM?"
                checked={wmoModal.add_to_crm}
                onChange={(event: any) =>
                  setWmoModal({ ...wmoModal, add_to_crm: event.target.checked })
                }
              />
            </Stack>
          </Box>
        }
        onPositive={() =>
          handleWmoUpdate(wmoModal._id, 'approved', wmoModal.add_to_crm, '')
        }
        onNegative={closeConfirmationWMODialog}
      />

      <ConfirmationDialog
        open={wmoModal.block}
        title="Block User"
        description="Are you sure you want to block this user? This will also remove all restaurants added by them."
        onPositive={() => handleBlock(wmoModal._id, 'wmo')}
        onNegative={closeConfirmationWMODialog}
      />

      <ConfirmationDialog
        open={wmoModal.reject}
        title="Reject"
        description={
          <Box>
            <Typography component="p">
              You are about to reject this restaurant.
            </Typography>
            <Stack direction="row" alignItems="center" mt={2}>
              <Textarea
                sx={{ width: '100%' }}
                minRows={4}
                onChange={(event: any) =>
                  setWmoModal({ ...wmoModal, reason: event.target.value })
                }
              />
            </Stack>
          </Box>
        }
        onPositive={() =>
          handleWmoUpdate(wmoModal._id, 'disapproved', false, wmoModal.reason)
        }
        onNegative={closeConfirmationWMODialog}
      />
    </>
  );
};

export default PlacesPage;
