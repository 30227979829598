import React, { FC, PropsWithChildren } from 'react';
import { Box, styled } from '@mui/joy';
import { colors } from '../../../configs/theme';
import { useMediaQuery } from 'react-responsive';

const Container = styled(Box)({
  minHeight: '100vh',
  paddingTop: 32,
  paddingRight: 32,
  paddingBottom: 32,
  backgroundColor: colors.isabellineLight,
  boxSizing: 'border-box',
  transition: 'all 0.2s ease-in-out',
  overflowX: 'hidden',
});

const AppContent: FC<PropsWithChildren> = ({ children }) => {
  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });

  return (
    <Container pl={isSm ? `${80 + 32}px` : `${240 + 32}px`}>
      {children}
    </Container>
  );
};

export default AppContent;
