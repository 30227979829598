import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Box,
  Input,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  Button,
  Typography,
} from '@mui/joy';
import { useQuery } from '@tanstack/react-query';
import countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import {
  apiGetUser,
  apiUpdateUserStatus,
  apiUpdateUserToScammer,
  apiUpdateUserWhitelist,
} from '../../../apis/users';
import LessonsTable from './LessonsTable';
import MappingTable from './MappingTable';
import TransactionTable from './TransactionTable';
import LinkedAccountsTable from './LinkedAccountsTable';
import { Menu, MenuItem } from '@mui/material';
countries.registerLocale(en);

const UserDetailPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [modals, setModals] = useState<any>({
    remove: false,
  });
  const [isLoadingScamAndWhitelist, setIsLoadingScamAndWhitelist] =
    useState<boolean>(false);
  const [isLoadingWhitelist, setIsLoadingWhitelist] = useState<boolean>(false);
  const [isLoadingScammer, setIsLoadingScammer] = useState<boolean>(false);
  const [openScammersModal, setOpenScammersModal] = useState<boolean>(false);
  const [openWhitelistModal, setOpenWhitelistModal] = useState<boolean>(false);
  const [openScamAndWhitelistModal, setOpenScamAndWhitelistModal] =
    useState<boolean>(false);

  const openConfirmationDialog = () => {
    setModals({
      ...modals,
      remove: true,
    });
  };

  const closeConfirmationDialog = () => {
    setModals({
      ...modals,
      remove: false,
    });
  };

  const handleClickActions = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseActions = () => {
    setAnchorEl(null);
  };

  const updateUser = async () => {
    if (id) {
      await apiUpdateUserStatus(id, !data?.disabled);
      refetch();
      closeConfirmationDialog();
    }
  };

  const handleUpdateWhitelist = async (status: boolean) => {
    setAnchorEl(null);
    if (id) {
      setIsLoadingWhitelist(true);
      await apiUpdateUserWhitelist(id, status);
      refetch();
      setIsLoadingWhitelist(false);
      setOpenWhitelistModal(false);
    }
  };

  const handleUpdateScammer = async (status: boolean) => {
    setAnchorEl(null);
    if (id) {
      setIsLoadingScammer(true);
      await apiUpdateUserToScammer(id, status);
      setIsLoadingScammer(false);
      setOpenScammersModal(false);
      navigate(-1);
    }
  };

  const handleScamAndWhitelist = async () => {
    setAnchorEl(null);
    if (id) {
      setIsLoadingScamAndWhitelist(true);
      await apiUpdateUserToScammer(id, false);
      await apiUpdateUserWhitelist(id, true);
      setIsLoadingScamAndWhitelist(false);
      setOpenScamAndWhitelistModal(false);
      navigate(-1);
    }
  };

  const { data, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      if (id) {
        const user = await apiGetUser(id);
        return {
          ...user.data,
        };
      }
    },
  });

  return (
    <>
      <Box>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={4}>
            <Button size="sm" color="success" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Typography level="h3">{data?.username ?? ''}</Typography>
          </Stack>
          <Stack direction="row" gap={4}>
            <Button size="sm" color="success" onClick={handleClickActions}>
              Actions
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleCloseActions}>
              {data?.scammed && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenScammersModal(true);
                    }}
                  >
                    Remove from Scam
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenScamAndWhitelistModal(true);
                    }}
                  >
                    Remove and Whitelist
                  </MenuItem>
                </>
              )}
              {!data?.scammed && data?.whitelisted && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenScammersModal(true);
                    }}
                  >
                    Add to Scam
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenWhitelistModal(true);
                    }}
                  >
                    Remove from Whitelist
                  </MenuItem>
                </>
              )}
              {!data?.scammed && !data?.whitelisted && (
                <>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenScammersModal(true);
                    }}
                  >
                    Add to Scam
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      setOpenWhitelistModal(true);
                    }}
                  >
                    Add to Whitelist
                  </MenuItem>
                </>
              )}
            </Menu>
            {/* <Button
              size="sm"
              color="success"
              onClick={() => {
                if (data?.whitelisted) {
                  handleUpdateWhitelist(false);
                } else {
                  handleUpdateWhitelist(true);
                }
              }}
            >
              {data?.whitelisted ? 'Remove from Whitelist' : 'Add to Whitelist'}
            </Button>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                if (data?.scammed) {
                  handleUpdateScammer(false);
                } else {
                  handleUpdateScammer(true);
                }
              }}
            >
              {data?.scammed
                ? 'Remove from Scammers List'
                : 'Add to Scammers List'}
            </Button> */}
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{ width: '100%' }} gap={2} mt={2}>
          <Stack sx={{ width: '100%' }}>
            <Typography>Country</Typography>
            <Input
              value={
                data?.region
                  ?.map((item: string) => countries.getName(item, 'en'))
                  .toString() || ''
              }
              disabled
            />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography>Wallet Address</Typography>
            <Input value={data?.wallet_address ?? ''} disabled />
          </Stack>
          <Stack sx={{ width: '100%' }}>
            <Typography>Telegram</Typography>
            <Input value={data?.telegram_id ?? ''} disabled />
          </Stack>
        </Stack>

        <Stack mt={2}>
          <Typography>
            User Status:{' '}
            <span style={{ color: data?.disabled ? 'red' : 'green' }}>
              {data?.disabled ? 'Disabled' : 'Active'}
            </span>
          </Typography>
        </Stack>
      </Box>
      <Box mt={4}>
        <Tabs aria-label="Basic tabs" defaultValue={0}>
          <TabList>
            <Tab>Lessons</Tab>
            <Tab>Mapping</Tab>
            <Tab>Transactions</Tab>
            {data?.scammed === true && <Tab>Linked Accounts</Tab>}
          </TabList>
          <TabPanel value={0}>
            <LessonsTable id={id} />
          </TabPanel>
          <TabPanel value={1}>
            <MappingTable id={id} />
          </TabPanel>
          <TabPanel value={2}>
            <TransactionTable id={id} />
          </TabPanel>
          {data?.scammed === true && (
            <TabPanel value={3}>
              <LinkedAccountsTable id={id} />
            </TabPanel>
          )}
        </Tabs>
      </Box>
      <Stack mt={4} direction={'row-reverse'}>
        <Button size="sm" color="success" onClick={openConfirmationDialog}>
          {data?.disabled ? 'Active' : 'Disable'}
        </Button>
      </Stack>

      <ConfirmationDialog
        open={modals.remove}
        title={data?.disabled ? 'Active User' : 'Disable User'}
        description={`Are you sure to ${
          data?.disabled ? 'active' : 'disable'
        } current User?`}
        onPositive={updateUser}
        onNegative={closeConfirmationDialog}
      />

      <ConfirmationDialog
        open={openScammersModal}
        title={
          data?.scammed ? 'Remove from Scammers List' : 'Add to Scammers List'
        }
        description={`Are you sure to ${
          data?.scammed ? 'Remove from' : 'Add to'
        } Scammers List?`}
        loading={isLoadingScammer}
        onPositive={() => {
          if (data?.scammed) {
            handleUpdateScammer(false);
          } else {
            handleUpdateScammer(true);
          }
        }}
        onNegative={() => setOpenScammersModal(false)}
      />

      <ConfirmationDialog
        open={openWhitelistModal}
        title={data?.whitelisted ? 'Remove from Whitelist' : 'Add to Whitelist'}
        description={`Are you sure to ${
          data?.whitelisted ? 'Remove from' : 'Add to'
        } Whitelist?`}
        loading={isLoadingWhitelist}
        onPositive={() => {
          if (data?.whitelisted) {
            handleUpdateWhitelist(false);
          } else {
            handleUpdateWhitelist(true);
          }
        }}
        onNegative={() => setOpenWhitelistModal(false)}
      />

      <ConfirmationDialog
        open={openScamAndWhitelistModal}
        title="Remove from Scammers List and Add to Whitelist"
        description={`Are you sure to Remove from Scammers List and Add to Whitelist?`}
        loading={isLoadingScamAndWhitelist}
        onPositive={() => {
          handleScamAndWhitelist();
        }}
        onNegative={() => setOpenWhitelistModal(false)}
      />
    </>
  );
};

export default UserDetailPage;
