import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { IPlaceModel, IWmoModel } from './types';

export const apiGetPlaces = async ({
  search,
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
  filter,
  only_mapped,
  only_added,
}: {
  search: string;
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  filter?: any;
  only_mapped?: boolean;
  only_added?: boolean;
}) => {
  try {
    const response = await axios.post<{
      total: number;
      data: IPlaceModel[];
      total_filtered_places: number;
      total_mapped_places: number;
    }>('/api/admin/v1/m2e/places', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(Object.keys(filter)?.length > 0 && {
        filter: filter,
      }),
      ...(search && {
        search,
      }),
      only_mapped,
      only_added,
    });
    return {
      rows: response.data.data,
      total: response.data.total,
      total_filtered_places: response.data.total_filtered_places,
      total_mapped_places: response.data.total_mapped_places,
    };
  } catch {
    enqueueSnackbar('Failed to load places data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};

export const apiGetPlacesAll = async () => {
  try {
    const response = await axios.post('/api/admin/v1/m2e/places', {
      only_mapped: true,
    });
    return [...response.data.data];
  } catch (e) {
    console.log('e', e);
    return [];
  }
};

export const apiGetPlaceDetail = async (id: string) => {
  try {
    const response = await axios.get(`/api/admin/v1/m2e/place/${id}`);
    return { ...response.data.data };
  } catch (e) {
    enqueueSnackbar('Failed to load place detail data', { variant: 'error' });
    return {};
  }
};

export const apiUpdatePlace = async (
  id: string | undefined,
  status: string,
  reject_reason: string | undefined,
) => {
  try {
    await axios.put(`/api/admin/v1/m2e/place/${id}`, {
      status: status,
      reject_reason: reject_reason,
    });
    enqueueSnackbar('Successfully update the place', { variant: 'success' });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update place', { variant: 'error' });
    return false;
  }
};

export const apiRejectMappedplace = async (
  id: string | undefined,
  reject_reason: string | undefined,
) => {
  try {
    await axios.put(`/api/admin/v1/m2e/place/${id}/detail/reject`, {
      reject_reason: reject_reason,
    });
    enqueueSnackbar('Successfully update the place', { variant: 'success' });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update place', { variant: 'error' });
    return false;
  }
};

export const apiBlockAndReject = async (id: string | undefined) => {
  try {
    await axios.delete(`/api/admin/v1/m2e/block/${id}`);
    enqueueSnackbar('Successfully User Block and Reject', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to Block and Reject', { variant: 'error' });
    return false;
  }
};

export const apiVerifyDetails = async (id: string | undefined, xp: number) => {
  try {
    await axios.put(`/api/admin/v1/m2e/place/${id}/detail`, {
      xp: xp,
    });
    return true;
  } catch (e: any) {
    if (e.response?.data?.message === 'NOT_CLAIMED') {
      enqueueSnackbar('Failed to verify details because it is not claimed', {
        variant: 'error',
      });
    } else {
      enqueueSnackbar('Failed to verify details', {
        variant: 'error',
      });
    }
    return false;
  }
};

export const apiGetWmos = async ({
  pageIndex,
  pageSize,
  sortBy,
  sortOrder,
  filter,
}: {
  pageIndex: number;
  pageSize: number;
  sortBy: string;
  sortOrder: string;
  filter?: any;
}) => {
  try {
    const response = await axios.post<{
      total: number;
      data: IWmoModel[];
    }>('/api/admin/v1/m2e/wmos', {
      page: pageIndex,
      limit: pageSize,
      ...(sortBy &&
        sortOrder && {
          sort: [{ field: sortBy, order: sortOrder }],
        }),
      ...(Object.keys(filter)?.length > 0 && {
        filter: filter,
      }),
    });
    return {
      rows: response.data.data,
      total: response.data.total,
    };
  } catch {
    enqueueSnackbar('Failed to load places data', { variant: 'error' });
    return {
      total: 0,
      rows: [],
    };
  }
};

export const apiGetWmoDetail = async (id: string) => {
  try {
    const response = await axios.get(`/api/admin/v1/m2e/wmo/${id}`);
    return { ...response.data.data };
  } catch (e) {
    enqueueSnackbar('Failed to load place detail data', { variant: 'error' });
    return {};
  }
};

export const apiVerifyWmo = async (
  id: string | undefined,
  status: string,
  add_to_crm: boolean,
  reason: string,
) => {
  try {
    await axios.put(`/api/admin/v1/m2e/wmo/${id}`, {
      status: status,
      add_to_crm,
      reject_reason: reason,
    });
    enqueueSnackbar('Successfully updated WMO', { variant: 'success' });
  } catch (e) {
    enqueueSnackbar('Failed to update WMO', { variant: 'error' });
    return {};
  }
};

export const apiValidateDetails = async (id: string | undefined, data: any) => {
  try {
    await axios.put(`/api/admin/v1/m2e/place/${id}/detail/validate`, data);
    return true;
  } catch (e: any) {
    if (e?.response?.data?.message === 'NOT_CLAIMED') {
      enqueueSnackbar('This place should have details', { variant: 'error' });
    } else {
      enqueueSnackbar('Failed to validate details', { variant: 'error' });
    }
    return false;
  }
};

export const apiRejectReason = async (
  id: string | undefined,
  reason: string,
) => {
  try {
    await axios.put(`/api/admin/v1/m2e/place/${id}/detail/reject`, {
      reject_reason: reason,
    });
    return true;
  } catch (e: any) {
    enqueueSnackbar('There is some issue', { variant: 'error' });
    return false;
  }
};
