import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

export const apiGetTotalInfo = async (network: string) => {
  try {
    const response = await axios.get(
      '/api/admin/v1/dashboard/total/' + network,
    );

    return {
      ...response.data.data,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load dashboard data', { variant: 'error' });
    return {
      total_users: 0,
      total_chapters: 0,
      total_paid: 0,
      total_lessons: 0,
      total_transactions: 0,
    };
  }
};

export const apiGetWalletsInfo = async (network: string) => {
  try {
    const response = await axios.get(
      '/api/admin/v1/dashboard/wallets-info/' + network,
    );

    return {
      ...response.data.data,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load dashboard data', { variant: 'error' });
    return {
      treasury_wallet_balance: 0,
      gas_wallet_balance: 0,
    };
  }
};

export const apiGetEarnedXp = async () => {
  try {
    const response = await axios.post(`/api/admin/v1/dashboard/earned-xp`);

    return response.data.data;
  } catch (e) {
    return 0;
  }
};

export const apiGetEarnedXpByScammers = async () => {
  try {
    const response = await axios.post(
      `/api/admin/v1/dashboard/scammer-earned-xp`,
    );
    return response.data.data;
  } catch (e) {
    return 0;
  }
};

export const apiGetChaptersCompletion = async () => {
  try {
    const response = await axios.get(
      '/api/admin/v1/dashboard/chapters-completion',
    );

    return response.data.data;
  } catch (e) {
    return {};
  }
};

export const apiGetActiveUsers = async (type: string) => {
  try {
    const response = await axios.post('/api/admin/v1/dashboard/active-users', {
      type,
    });

    return response.data.data;
  } catch (e) {
    return [];
  }
};
