import React, { FC } from 'react';
import { Card, CardContent, Box, Typography, Stack } from '@mui/joy';
import { Spinner } from '../indicators/Spinner';
import { colors } from '../../configs/theme';

interface IStatCardProps {
  title: string;
  value: number | string | any;
  variant?: 'dark' | 'light' | 'dark-blue' | 'light-blue';
  loading?: boolean;
  unit?: string;
}

type ColorKey = keyof typeof colors;

const variants: {
  [key: string]: {
    bgcolor: ColorKey;
    color: ColorKey;
  };
} = {
  dark: {
    bgcolor: 'raisinBlack',
    color: 'white',
  },
  'dark-blue': {
    bgcolor: 'royalBlueDark',
    color: 'white',
  },
  'light-blue': {
    bgcolor: 'beauBlue',
    color: 'raisinBlack',
  },
  light: {
    bgcolor: 'gray400',
    color: 'raisinBlack',
  },
};

const StatCard: FC<IStatCardProps> = ({
  title,
  value,
  variant = 'dark',
  loading = false,
  unit,
}) => {
  return (
    <Card
      sx={{
        border: 'none',
        bgcolor: variants[variant].bgcolor,
        minWidth: 180,
      }}
    >
      <CardContent>
        <Typography
          level="body-md"
          textColor={variants[variant].color}
          fontWeight={500}
        >
          {title}
        </Typography>
        {loading ? (
          <Box mt={1.25}>
            <Spinner color={colors[variants[variant].color]} size={24} />
          </Box>
        ) : (
          <Stack direction={'row'} gap={1} alignItems={'flex-end'}>
            <Typography
              mt={1}
              level="h2"
              lineHeight={1}
              textColor={variants[variant].color}
            >
              {typeof value === 'number' ? value.toLocaleString() : value}
            </Typography>
            <Typography
              mt={1}
              level="h4"
              lineHeight={1}
              textColor={variants[variant].color}
            >
              {unit ?? ''}
            </Typography>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default StatCard;
