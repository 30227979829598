import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StatCard from '../../components/widgets/StatCard';
import { Box, Button, Grid, Typography, IconButton, Stack } from '@mui/joy';
import { EditIcon, TrashIcon } from 'lucide-react';
import { getSortingStateFromQuery } from '../../components/functions';

import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import DataTable from '../../components/datatable/DataTable';
import { apiDeleteLessons, apiGetLessons } from '../../apis/lessons';
import { apiGetChapter, apiPutChapter } from '../../apis/chapters';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import CreateLessonDialog from './CreateLessonDialog';
import EditLessonDialog from './EditLessonDialog';
import EditChallengeDialog from './EditChallengeDialog';
import { IChapterModel } from '../../apis/types';

interface ILessonsListModal {
  _id?: string;
  title?: string;
  total_questions?: number;
  total_pages?: number;
}

const ChallengeManagement: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [modals, setModals] = useState<any>({
    create: false,
    edit: {
      open: false,
      lessonId: null,
    },
    removeConfirmation: {
      open: false,
      lessonId: null,
    },
    editChallenge: false,
  });
  const [totalLesson, setTotalLesson] = useState<number>(0);
  const [totalQuestion, setTotalQuestion] = useState<number>(0);

  const closeConfirmationDialog = () => {
    setModals({
      ...modals,
      removeConfirmation: { open: false, lessonId: null },
    });
  };

  const removeLesson = async () => {
    await apiDeleteLessons(modals.removeConfirmation.lessonId);
    closeConfirmationDialog();
    refetch();
  };

  const openEditChallengeDialog = () => {
    setModals({
      ...modals,
      editChallenge: true,
    });
  };

  const closeEditChallengeDialog = () => {
    setModals({
      ...modals,
      editChallenge: false,
    });
  };

  const updateChallenge = async (chapter: IChapterModel) => {
    await apiPutChapter(id ?? '', chapter);
    closeEditChallengeDialog();
    refetch();
  };

  const openConfirmationDialog = (lessonId: string | undefined) => {
    setModals({
      ...modals,
      removeConfirmation: { open: true, lessonId: lessonId },
    });
  };

  const openCreateLessonDialog = useCallback(() => {
    setModals({
      ...modals,
      create: true,
    });
  }, [setModals]);

  const closeCreateLessonDialog = useCallback(() => {
    setModals({
      ...modals,
      create: false,
    });
  }, [setModals]);

  const openEditLessonDialog = useCallback(
    (lessonId: string | undefined) => {
      setModals({
        ...modals,
        edit: {
          open: true,
          lessonId,
        },
      });
    },
    [setModals],
  );

  const closeEditLessonDialog = useCallback(() => {
    setModals({
      ...modals,
      edit: {
        open: false,
        lessonId: null,
      },
    });
  }, [setModals]);

  const updateEditLesson = useCallback(async () => {
    closeEditLessonDialog();
    refetch();
  }, [setModals]);

  const createNewLesson = useCallback(async () => {
    setModals({
      ...modals,
      create: false,
    });
    refetch();
  }, [setModals]);

  const [sorting, setSorting] = useState<SortingState>(
    getSortingStateFromQuery(new URLSearchParams(), [
      '_id',
      'title',
      'total_questions',
      'total_pages',
    ]),
  );

  const { data, isLoading, isPlaceholderData, refetch } = useQuery({
    queryKey: ['lessons'],
    queryFn: async () => {
      if (id) {
        const lessons = await apiGetLessons(id);
        const chapter = await apiGetChapter(id);

        const rows = lessons.data
          ? await Promise.all(
              lessons.data.map(async (item) => {
                return {
                  _id: item._id,
                  title: item.title || '',
                  total_pages: item.pages.length,
                  total_questions: item.questions.length,
                };
              }),
            )
          : [];
        return {
          rows: rows,
          total_lessons: lessons.total_lessons,
          total_questions: lessons.total_questions,
          challenge: {
            title: chapter.data.title || '',
            locked: chapter.data.locked,
          },
        };
      }
    },
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (data) {
      setTotalLesson(data.total_lessons ?? 0);
      setTotalQuestion(data.total_questions ?? 0);
    }
  }, [data]);

  const helper = useMemo(() => {
    return createColumnHelper<Partial<ILessonsListModal>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),
      helper.accessor('title', {
        header: 'Lesson Title',
        cell: (info) => info.getValue(),
        size: 450,
        minSize: 450,
        maxSize: 450,
      }),
      helper.accessor('total_pages', {
        header: 'Total Pages',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('total_questions', {
        header: 'Total Questions',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                openEditLessonDialog(info.row.original._id);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: 0,
    state: {
      sorting: sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <>
      <Stack
        direction={'row'}
        sx={{ width: '100%' }}
        alignItems={'center'}
        gap={4}
      >
        <Button
          size="sm"
          color="success"
          onClick={() => navigate(`/learn-to-earn`)}
        >
          Back
        </Button>
        <Typography level="h2">{data?.challenge?.title ?? ''}</Typography>
      </Stack>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Lessons"
              value={totalLesson ?? 0}
              variant="dark-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Questions"
              value={totalQuestion ?? 0}
              variant="light"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack direction={'row'} mt={4} gap={2}>
        <Button onClick={openCreateLessonDialog}>Create a lesson</Button>
        <Button onClick={openEditChallengeDialog}>Edit a chapter</Button>
      </Stack>
      <Box mt={4}>
        <DataTable
          table={table}
          loading={isLoading || isPlaceholderData}
          pagination={false}
        />
      </Box>
      <EditChallengeDialog
        open={modals.editChallenge}
        challenge={data?.challenge}
        onClose={closeEditChallengeDialog}
        onNegative={closeEditChallengeDialog}
        onPositive={updateChallenge}
      />
      <ConfirmationDialog
        open={modals.removeConfirmation.open}
        title="Remove Lesson"
        description="Are you sure to delete current lesson?"
        onPositive={removeLesson}
        onNegative={closeConfirmationDialog}
      />
      <EditLessonDialog
        chapter={id}
        lesson={modals.edit.lessonId}
        open={modals.edit.open}
        onClose={closeEditLessonDialog}
        onNegative={closeEditLessonDialog}
        onPositive={updateEditLesson}
      />
      <CreateLessonDialog
        chapter={id}
        open={modals.create}
        onClose={closeCreateLessonDialog}
        onNegative={closeCreateLessonDialog}
        onPositive={createNewLesson}
      />
    </>
  );
};

export default ChallengeManagement;
