import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import React, { FC, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ImageUp } from 'lucide-react';
import CustomInput from '../../components/forms/CustomInput';

interface ICreatePageDialogProps {
  open?: boolean;
  onPositive: (title: string, content: string, image: File | null) => void;
  onNegative: () => void;
  onClose: () => void;
}

const CreatePageDialog: FC<ICreatePageDialogProps> = ({
  open = false,
  onPositive,
  onNegative,
  onClose,
}) => {
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [validated, setValidated] = useState<boolean>(false);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth={600} maxWidth={600}>
        <ModalClose />
        <DialogTitle>Create Page</DialogTitle>
        <DialogContent>
          <Stack gap={1} mt={1}>
            <CustomInput
              required
              validation={validated}
              title="Page Title"
              placeholder="Input your page title"
              onChange={(e) => {
                setTitle(e);
              }}
            />

            <Stack flexDirection={'row'} gap={3}>
              <label htmlFor="file-input-logo" style={{ cursor: 'pointer' }}>
                <ImageUp />
              </label>

              <input
                type="file"
                id="file-input-logo"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(event) => setImage(event.target.files?.[0] || null)}
              />
              {image && <Typography>{image.name}</Typography>}
            </Stack>

            <ReactQuill
              theme="snow"
              modules={{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],
                  ['link', 'image', 'video'],
                  [{ header: 1 }, { header: 2 }],
                  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                  [{ script: 'sub' }, { script: 'super' }],
                  [{ indent: '-1' }, { indent: '+1' }],
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
              ]}
              placeholder="Edit page details here..."
              onChange={setContent}
            />
            {validated && content === '' && (
              <Typography
                component="span"
                sx={{ fontSize: '12px', color: 'red' }}
              >
                This field is required
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button variant="outlined" onClick={onNegative}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setValidated(true);
                if (title && content) {
                  onPositive(title, content, image);
                  setImage(null);
                  setValidated(false);
                }
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
export default CreatePageDialog;
