import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IL2EState {
  pageIndex: number | null;
  pageSize: number | null;
}

const initialState: IL2EState = {
  pageIndex: null,
  pageSize: null,
};

export const l2eSlice = createSlice({
  name: 'l2e',
  initialState: initialState as IL2EState,
  reducers: {
    setL2EState(state, action: PayloadAction<IL2EState>) {
      return action.payload;
    },
    resetL2EState() {
      return initialState;
    },
  },
});

export const { setL2EState, resetL2EState } = l2eSlice.actions;
