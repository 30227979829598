import React, { FC, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from '@mui/joy';

import DataTable from '../../../components/datatable/DataTable';
import { IUserTransactionModel } from '../../../apis/types';
import { apiGetTransactionUser } from '../../../apis/users';
import moment from 'moment';

interface ITransactionTableProps {
  id: string | undefined;
}

const TransactionTable: FC<ITransactionTableProps> = ({ id }) => {
  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserTransactionModel>>();
  }, []);

  const { data, isLoading, isPlaceholderData } = useQuery({
    queryKey: ['mapping', id],
    queryFn: async () => {
      if (id) {
        const transaction = await apiGetTransactionUser(id);
        return {
          rows: transaction.data,
          total: 0,
        };
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(() => {
    return [
      helper.accessor('type', {
        header: 'Transaction Type',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('date', {
        header: 'Date/Time',
        cell: (info) => {
          if (info.getValue()) {
            return moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');
          } else {
            return null;
          }
        },
      }),
      helper.accessor('amount', {
        header: 'XP Token',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.display({
        header: 'Transaction',
        cell: (info) =>
          info.row.original.transaction_link ? (
            <Link
              href={info.row.original.transaction_link}
              color="success"
              target="_blank"
            >
              View
            </Link>
          ) : (
            <></>
          ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
  });

  return (
    <DataTable
      table={table}
      loading={isLoading || isPlaceholderData}
      pagination={false}
    />
  );
};

export default TransactionTable;
