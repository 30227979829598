import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { ISignInRequest } from './types';
import { store } from '../redux/store';
import { login, logout } from '../redux/auth.slice';

export const apiSignIn = async (data: ISignInRequest) => {
  try {
    const response = await axios.post('/api/admin/v1/user/login', {
      email: data.email,
      password: data.password,
    });

    store.dispatch(
      login({
        id: '#',
        name: 'Administrator',
        email: data.email,
        accessToken: response.data.data,
        refreshToken: response.data.data,
        isSessionRemembered: data.remember_me,
      }),
    );
    if (data.remember_me) {
      localStorage.setItem('uco-admin-portal-auth', 'true');
    } else {
      localStorage.removeItem('uco-admin-portal-auth');
    }

    enqueueSnackbar('You have signed in successfully', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Please check your credentials and try again', {
      variant: 'error',
    });
    return false;
  }
};

export const apiSignOut = async () => {
  store.dispatch(logout());
  enqueueSnackbar('You have logged out successfully', {
    variant: 'info',
  });
  return true;
};
