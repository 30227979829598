import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, IconButton, Stack } from '@mui/joy';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { EditIcon, TrashIcon } from 'lucide-react';

import CreateChallengeDialog from './CreateChallengeDialog';
import StatCard from '../../components/widgets/StatCard';
import DragDataTable from '../../components/datatable/DragDataTable';
import OrganizationStatusChip from '../../components/chips/OrganizationStatusChip';
import { getSortingStateFromQuery } from '../../components/functions';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {
  apiGetChapters,
  apiPostChapter,
  apiDeleteChapter,
  apiReorderChapter,
} from '../../apis/chapters';
import { apiGetLessons } from '../../apis/lessons';
import { setL2EState } from '../../redux/l2e.slice';
import { IRootState, store } from '../../redux/store';

interface IChapterModal {
  _id?: string;
  title?: string;
  index?: number;
  owner?: string;
  total_lessons?: number;
  total_questions?: number;
  total_xp?: number;
  status?: string;
}

const LearnToEarn: FC = () => {
  const l2e = useSelector((state: IRootState) => state.l2e);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [totalLessons, setTotalLessons] = useState<number>(0);
  const [totalQuestions, setTotalQuestions] = useState<number>(0);

  const [modals, setModals] = useState<any>({
    create: false,
    removeConfirmation: {
      open: false,
      chapterId: null,
    },
  });

  const openConfirmationDialog = (chapterId: string | undefined) => {
    setModals({
      ...modals,
      removeConfirmation: { open: true, chapterId: chapterId },
    });
  };

  const closeConfirmationDialog = () => {
    setModals({
      ...modals,
      removeConfirmation: { open: false, chapterId: null },
    });
  };
  const removeChallenge = async () => {
    await apiDeleteChapter(modals.removeConfirmation.chapterId);
    closeConfirmationDialog();
    refetch();
  };

  const openCreateChallengeDialog = useCallback(() => {
    setModals({
      ...modals,
      create: true,
    });
  }, [setModals]);

  const closeCreateChallengeDialog = useCallback(() => {
    setModals({
      ...modals,
      create: false,
    });
  }, [setModals]);

  const createNewChapter = useCallback(
    async (name: string) => {
      if (await apiPostChapter(name)) {
        await refetch();
      }
      setModals({
        ...modals,
        create: false,
      });
    },
    [setModals],
  );

  const dropDraggedRow = async (id: string, value: number) => {
    await apiReorderChapter(id, value);
    refetch();
  };

  const [sorting, setSorting] = useState<SortingState>(
    getSortingStateFromQuery(params, ['_id', 'name', 'owner', 'phase']),
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex'))
      ? Number(params.get('pageIndex')) - 1
      : l2e.pageIndex
        ? l2e.pageIndex
        : 0,
    pageSize: 100,
  });

  const { data, isLoading, isPlaceholderData, refetch } = useQuery({
    queryKey: ['chapters', params.get('pageIndex'), params.get('pageSize')],
    queryFn: async () => {
      const pageIndex = Number(params.get('pageIndex')) || l2e.pageIndex || 1;
      const pageSize = 100;

      const data = await apiGetChapters({
        pageIndex,
        pageSize,
      });

      const rows = await Promise.all(
        data.rows.map(async (item) => {
          const lessons = await apiGetLessons(item._id);

          return {
            _id: item._id,
            title: item.title,
            total_lessons: lessons.total_lessons,
            total_questions: lessons.total_questions,
            total_xp: item.total_xp || 0,
          };
        }),
      );

      return {
        rows,
        total: data.total,
      };
    },
    placeholderData: keepPreviousData,
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IChapterModal>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),
      helper.accessor('title', {
        header: 'Challenge Name',
        cell: (info) => info.getValue(),
        size: 300,
        minSize: 300,
        maxSize: 300,
      }),
      helper.accessor('owner', {
        header: 'Owner',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('total_lessons', {
        header: 'Total Lessons',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('total_questions', {
        header: 'Total questions',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('total_xp', {
        header: 'Total Xp',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('status', {
        header: 'Status',
        cell: (info) => <OrganizationStatusChip status={info.getValue()} />,
        enableSorting: false,
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton
              size="sm"
              color="primary"
              onClick={() => {
                navigate(`challenge/${info.row.original._id}`);
              }}
            >
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() => {
                openConfirmationDialog(info.row.original._id);
              }}
            >
              <TrashIcon size={16} />
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (pagination.pageIndex + 1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });

    store.dispatch(
      setL2EState({
        pageIndex: pagination?.pageIndex,
        pageSize: pagination?.pageSize,
      }),
    );
  }, [pagination]);

  useEffect(() => {
    if (data?.rows) {
      let lesson = 0;
      let question = 0;
      data.rows.map((item) => {
        lesson += item.total_lessons ?? 0;
        question += item.total_questions ?? 0;
      });
      setTotalLessons(lesson);
      setTotalQuestions(question);
    }
  }, [data]);

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Challenges"
              value={data?.total ?? 0}
              variant="dark"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Lessons"
              value={totalLessons ?? 0}
              variant="dark-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Questions"
              value={totalQuestions ?? 0}
              variant="light"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Button onClick={openCreateChallengeDialog}>Create a challenge</Button>
      </Box>
      <Box mt={4}>
        <DragDataTable
          table={table}
          loading={isLoading || isPlaceholderData}
          pagination={false}
          onDragDrop={(_id, value) => dropDraggedRow(_id, value)}
        />
      </Box>
      <CreateChallengeDialog
        challenge={data?.total ?? 0 + 1}
        open={modals.create}
        onClose={closeCreateChallengeDialog}
        onNegative={closeCreateChallengeDialog}
        onPositive={createNewChapter}
      />
      <ConfirmationDialog
        open={modals.removeConfirmation.open}
        title="Remove Challenge"
        description="Are you sure to delete current Challenge?"
        onPositive={removeChallenge}
        onNegative={closeConfirmationDialog}
      />
    </>
  );
};
export default LearnToEarn;
