import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { ILessonModel } from './types';

export const apiGetLessons = async (id: string) => {
  try {
    const response = await axios.get<{
      total_lessons?: number;
      total_questions?: number;
      data?: ILessonModel[];
    }>(`/api/admin/v1/l2e/lessons/${id}`);

    return {
      total_lessons: response.data.total_lessons,
      total_questions: response.data.total_questions,
      data: response.data.data,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load lessons data', { variant: 'error' });
    return {
      total_lessons: 0,
      total_questions: 0,
      data: [],
    };
  }
};

export const apiDeleteLessons = async (id: string) => {
  try {
    await axios.delete(`/api/admin/v1/l2e/lesson/${id}`);
    enqueueSnackbar('Successfully deleted the selected lesson', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected lesson', {
      variant: 'error',
    });
    return false;
  }
};

export const apiPostLesson = async (id: string, title: string) => {
  try {
    const response = await axios.post(`/api/admin/v1/l2e/lesson/${id}`, {
      title,
    });
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to post the lesson', {
      variant: 'error',
    });
    return false;
  }
};

export const apiPutLesson = async (data: Partial<ILessonModel>) => {
  try {
    await axios.put(`/api/admin/v1/l2e/lesson/${data._id}`, {
      title: data.title,
      pages: data.pages,
      questions: data.questions,
    });
    enqueueSnackbar('Successfully save the lesson', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to put the lesson', {
      variant: 'error',
    });
    return false;
  }
};
