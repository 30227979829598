import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CustomSnackbarProvider } from './components/providers/CustomSnackbarProvider';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';

import { persistor, store } from './redux/store';
import { theme } from './configs/theme';
import { routes } from './routes/routes';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const materialTheme = materialExtendTheme();

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
          <JoyCssVarsProvider theme={theme}>
            <QueryClientProvider client={client}>
              <SnackbarProvider
                autoHideDuration={1500}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                maxSnack={5}
                preventDuplicate
              >
                <CustomSnackbarProvider>
                  <RouterProvider router={routes} />
                </CustomSnackbarProvider>
              </SnackbarProvider>
            </QueryClientProvider>
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
