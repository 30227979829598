/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { Table, flexRender, Column, RowData } from '@tanstack/react-table';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  styled,
  Input,
  Autocomplete,
} from '@mui/joy';
import { colors } from '../../configs/theme';
import {
  SortAscendingIcon,
  SortDescendingIcon,
  SortDisabledIcon,
} from './Icons';
import { Spinner } from '../indicators/Spinner';
import Pagination from './Pagination';
import { FilterIcon, GripVertical } from 'lucide-react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

interface IDragDataTableProps {
  table: Table<any>;
  loading?: boolean;
  pagination?: boolean;
  onRowClick?: (row: any) => void | undefined;
  onDragDrop?: (_id: string, index: number) => Promise<void> | undefined;
}

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'select';
    filterOptions?: any[];
  }
}

const TableHead = styled('thead')`
  height: 40px;
  text-align: left;

  & th {
    box-sizing: border-box;
    padding: 0 16px;
    vertical-align: middle !important;
    color: ${colors.gray700};
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
`;

const TableBody = styled('tbody')`
  position: relative;
  & tr {
    & td {
      background-color: #ffffff;
      border-bottom: 1px solid ${colors.gray300};
    }
    &:first-of-type {
      & td:first-of-type {
        border-top-left-radius: 8px;
      }
      & td:last-of-type {
        border-top-right-radius: 8px;
      }
    }
    &:last-of-type {
      & td:first-of-type {
        border-bottom-left-radius: 8px;
      }
      & td:last-of-type {
        border-bottom-right-radius: 8px;
      }
    }
    &:hover td {
      background-color: #f8fafc;
    }
  }
  & td {
    height: auto;
    padding: 8px 16px;
    overflow: hidden;
    font-size: 14px;
    border-spacing: 0px;
    border: none;
  }
`;

const DragDataTable: FC<IDragDataTableProps> = ({
  table,
  loading,
  pagination = true,
  onRowClick,
  onDragDrop,
}) => {
  const [filterShow, setFilterShow] = useState<number>(0);
  const [rows, setRows] = useState<any[]>([]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const tempData: any[] = Array.from(rows);
    const [source_data] = tempData.splice(result.source.index, 1);
    tempData.splice(result.destination.index, 0, source_data);
    setRows(tempData);

    if (onDragDrop) {
      onDragDrop(
        String(result.draggableId),
        Number(result.destination.index + 1),
      );
    }
  };

  useEffect(() => {
    if (loading === false) {
      setRows([...table.getRowModel().rows]);
    }
  }, [loading]);

  return (
    <>
      <Box
        sx={{
          maxWidth: '100%',
          overflowX: 'auto',
        }}
      >
        <table
          style={{
            width: '100%',
            borderSpacing: 0,
          }}
        >
          <DragDropContext onDragEnd={handleDragEnd}>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  <th key={999} style={{ width: 100 }}></th>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      style={{
                        minWidth: header.column.columnDef.minSize
                          ? header.column.columnDef.minSize
                          : 80,
                        width: header.column.columnDef.size
                          ? header.column.columnDef.size
                          : 'auto',
                        maxWidth: header.column.columnDef.maxSize
                          ? header.column.columnDef.maxSize
                          : 'auto',
                      }}
                    >
                      <Stack direction="column">
                        {header.column.getCanFilter() &&
                        filterShow === index ? (
                          <Stack direction="row" alignItems="center">
                            <Filter column={header.column} />
                            {header.column.getCanFilter() ? (
                              <IconButton
                                sx={{
                                  ml: 0.5,
                                  width: 24,
                                  height: 24,
                                  minWidth: 0,
                                  minHeight: 0,
                                  padding: 0,
                                  pb: 0.25,
                                }}
                                onClick={() => {
                                  setFilterShow(0);
                                  header.column.setFilterValue('');
                                }}
                              >
                                <FilterIcon size={12} />
                              </IconButton>
                            ) : (
                              <span></span>
                            )}
                          </Stack>
                        ) : (
                          <Stack direction="row" alignItems="center">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                            {header.column.getCanSort() ? (
                              <IconButton
                                sx={{
                                  ml: 0.5,
                                  width: 24,
                                  height: 24,
                                  minWidth: 0,
                                  minHeight: 0,
                                  padding: 0,
                                  pb: 0.25,
                                }}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {{
                                  asc: <SortAscendingIcon />,
                                  desc: <SortDescendingIcon />,
                                }[header.column.getIsSorted() as string] ?? (
                                  <SortDisabledIcon />
                                )}
                              </IconButton>
                            ) : (
                              <span></span>
                            )}
                            {header.column.getCanFilter() ? (
                              <IconButton
                                sx={{
                                  ml: 0.5,
                                  width: 24,
                                  height: 24,
                                  minWidth: 0,
                                  minHeight: 0,
                                  padding: 0,
                                  pb: 0.25,
                                }}
                                onClick={() => setFilterShow(index)}
                              >
                                <FilterIcon size={12} />
                              </IconButton>
                            ) : (
                              <span></span>
                            )}
                          </Stack>
                        )}
                      </Stack>
                    </th>
                  ))}
                </tr>
              ))}
            </TableHead>
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                  {rows.length > 0 ? (
                    rows.map((row, index) => (
                      <Draggable
                        key={row.original._id}
                        draggableId={row.original._id}
                        index={index}
                      >
                        {(provider) => (
                          <tr
                            onClick={() =>
                              onRowClick && onRowClick(row.original._id)
                            }
                            key={row.id}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            <td key={999} {...provider.dragHandleProps}>
                              <GripVertical />
                            </td>
                            {row.getVisibleCells().map((cell: any) => (
                              <td
                                key={cell.id}
                                style={{
                                  minWidth: cell.column.columnDef.minSize
                                    ? cell.column.columnDef.minSize
                                    : 80,
                                  width: cell.column.columnDef.size
                                    ? cell.column.columnDef.size
                                    : 'auto',
                                  maxWidth: cell.column.columnDef.maxSize
                                    ? cell.column.columnDef.maxSize
                                    : 'auto',
                                }}
                                onClick={(e) => {
                                  if (cell.column.id === 'Actions') {
                                    e.stopPropagation();
                                  }
                                }}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </td>
                            ))}
                          </tr>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={table.getAllColumns().length}>
                        <Box
                          borderRadius="md"
                          bgcolor="white"
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          height={200}
                        >
                          <Typography textColor="spanishGray">
                            No data available
                          </Typography>
                        </Box>
                      </td>
                    </tr>
                  )}
                  {loading ? (
                    <Box
                      component="tr"
                      sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        borderRadius: 'md',
                        backgroundColor: `${colors.raisinBlack}1A`,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Stack
                        borderRadius="md"
                        bgcolor="white"
                        justifyContent="center"
                        alignItems="center"
                        p={2}
                        spacing={1}
                        component="td"
                      >
                        <Spinner />
                        <Typography>Loading</Typography>
                      </Stack>
                    </Box>
                  ) : null}
                  {provider.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </Box>
      {pagination ? (
        <Box mt={1}>
          <Pagination
            pageIndex={table.getState().pagination.pageIndex}
            pageSize={table.getState().pagination.pageSize}
            totalRows={table.getRowCount()}
            totalPages={table.getPageCount()}
            onChangePageSize={table.setPageSize}
            onChangePageIndex={table.setPageIndex}
            onFirstPage={table.firstPage}
            onLastPage={table.lastPage}
            onNextPage={table.nextPage}
            onPreviousPage={table.previousPage}
          />
        </Box>
      ) : null}
    </>
  );
};

const Filter = ({ column }: { column: Column<any, unknown> }) => {
  const columnFilterValue = column.getFilterValue();
  const { filterVariant, filterOptions } = column.columnDef.meta ?? {};

  return filterVariant === 'select' ? (
    <Autocomplete
      sx={{ width: '100%' }}
      options={filterOptions ? filterOptions : []}
      onChange={(e, value) => column.setFilterValue(value)}
    />
  ) : (
    <Input
      fullWidth
      onChange={(e) => column.setFilterValue(e.target.value)}
      value={(columnFilterValue ?? '') as string}
    />
  );
};

export default DragDataTable;
