import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import { Card, Chip, DialogContent } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import { apiGetWmoDetail, apiVerifyWmo } from '../../../apis/places';

const WmoDetailPage: FC = () => {
  const navigate = useNavigate();
  const { id, name } = useParams<{ id: string; name: string }>();

  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('approved');

  const { data, refetch } = useQuery({
    queryKey: ['wmo', id],
    queryFn: async () => {
      if (id) {
        const wmo = await apiGetWmoDetail(id);
        return {
          ...wmo,
        };
      }
    },
  });

  const handleVerify = async () => {
    setIsVerifying(true);
    await apiVerifyWmo(id ?? '', status, false, '');
    refetch();
    setIsVerifying(false);
    setOpen(false);
  };

  return (
    <>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={4}
        sx={{ maxWidth: '1100px' }}
      >
        <Button color="success" onClick={() => navigate(`/places`)}>
          Back
        </Button>

        {data?.status === 'approved' ? (
          <Typography sx={{ color: 'green' }}>Verified</Typography>
        ) : data?.status === 'disapproved' ? (
          <Typography sx={{ color: 'red' }}>Disapproved</Typography>
        ) : (
          <Stack direction="row" gap={2}>
            <Button
              onClick={() => {
                setOpen(true);
                setStatus('approved');
              }}
              color="success"
            >
              Approve WMO
            </Button>
            <Button
              onClick={() => {
                setOpen(true);
                setStatus('disapproved');
              }}
              color="success"
            >
              Disapprove WMO
            </Button>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" gap={4}>
        <Stack direction="row" mt={4} gap={8}>
          <Stack flex={1} direction="column" gap={2}>
            <Card
              sx={{
                padding: '24px 24px',
                display: 'flex',
                gap: 1.5,
                width: 293,
                flexDirection: 'column',
                backgroundColor: '#FAFAF8',
                borderRadius: 1,
                height: 300,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack direction="column" gap={2}>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="start"
                  alignItems="center"
                  gap={1}
                >
                  <Typography>Collector Name: </Typography>
                  <Typography level={'h4'}>
                    {data?.collector_name ?? ''}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Collector Email: </Typography>
                  <Typography level={'h4'}>
                    {data?.collector_email ?? ''}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Collector Phone Number: </Typography>
                  <Typography level={'h4'}>
                    {data?.collector_phone_number ?? ''}
                  </Typography>
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>

        <Stack direction="row" mt={4} gap={8}>
          <Stack flex={1} direction="column" gap={2}>
            <Card
              sx={{
                padding: '24px 24px',
                display: 'flex',
                gap: 1.5,
                width: 293,
                flexDirection: 'column',
                backgroundColor: '#FAFAF8',
                borderRadius: 1,
                height: 300,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack direction="column" gap={2}>
                <Stack
                  direction="row"
                  width="100%"
                  justifyContent="start"
                  alignItems="center"
                  gap={1}
                >
                  <Typography>Collector Email: </Typography>
                  <Typography level={'h4'}>
                    {data?.collector_email ?? ''}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Type: </Typography>
                  <Typography level={'h4'}>{data?.type ?? ''}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Verified: </Typography>
                  {data?.verified ? (
                    <Chip label="Verified" variant="outlined" color="success" />
                  ) : (
                    <Chip label="Not Verified" variant="outlined" />
                  )}
                </Stack>
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Stack>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog minWidth={320} maxWidth={480}>
          <ModalClose />
          <DialogTitle>Confirm</DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <Typography>
              You are about to verify <b>{name}</b> and award <b>500xp</b> to{' '}
              <b>{data?.collector_name}</b>.
            </Typography>
          </DialogContent>
          <Box>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Button onClick={handleVerify} loading={isVerifying}>
                Confirm
              </Button>
            </Stack>
          </Box>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default WmoDetailPage;
