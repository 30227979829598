import React, { FC, useState } from 'react';
import { Stack, Typography, Button, Box, Grid } from '@mui/joy';
import { HandCoins } from 'lucide-react';
import CustomInput from '../../components/forms/CustomInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { IRewardConstantsModel } from '../../apis/types';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import moment from 'moment';
import StatCard from '../../components/widgets/StatCard';
import {
  apiGetRewardConstants,
  apiPutRewardConstants,
} from '../../apis/rewards';

const schema = yup.object({
  referral_xp: yup.number(),
  m2e_xp: yup.number(),
  share_xp: yup.number(),
  m2e_image_xp: yup.number(),
  m2e_details_xp: yup.number(),
  m2e_phone_xp: yup.number(),
  m2e_wmo_xp: yup.number(),
  m2e_uco_xp: yup.number(),
  ratio: yup.number(),
  profile_xp: yup.number(),
  weekly_limit_token: yup.number(),
  xp_for_nft_mint: yup.number(),
});

const RewardsPage: FC = () => {
  const [ratio, setRatio] = useState<number>(0);
  const { control, handleSubmit, setValue } = useForm<IRewardConstantsModel>({
    defaultValues: {
      referral_xp: 0,
      m2e_xp: 0,
      share_xp: 0,
      m2e_image_xp: 0,
      m2e_details_xp: 0,
      m2e_phone_xp: 0,
      m2e_wmo_xp: 0,
      m2e_uco_xp: 0,
      profile_xp: 0,
      weekly_limit_token: 0,
      xp_for_nft_mint: 0,
    },
    resolver: yupResolver(schema),
  });

  const { data } = useQuery({
    queryKey: ['rewards'],
    queryFn: async () => {
      const response = await apiGetRewardConstants();

      setValue('referral_xp', response.referral_xp);
      setValue('m2e_xp', response.m2e_xp);
      setValue('share_xp', response.share_xp);
      setValue('m2e_image_xp', response.m2e_image_xp);
      setValue('m2e_details_xp', response.m2e_details_xp);
      setValue('m2e_phone_xp', response.m2e_phone_xp);
      setValue('m2e_wmo_xp', response.m2e_wmo_xp);
      setValue('m2e_uco_xp', response.m2e_uco_xp);
      setValue('profile_xp', response.profile_xp);
      setValue('weekly_limit_token', response.weekly_limit_token);
      setValue('xp_for_nft_mint', response.xp_for_nft_mint);
      setRatio(response.ratio);
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const onSubmit = async (values: IRewardConstantsModel) => {
    if (values) {
      await apiPutRewardConstants(values);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <HandCoins />
          <Typography level="h3">Rewards</Typography>
        </Stack>
        <Button type="submit">Update</Button>
      </Stack>

      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Reward Ratio B3TR/XP"
              value={ratio ?? 0}
              variant="dark"
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Stack direction="row" gap={4} mt={4}>
          <Stack direction="column" sx={{ width: '400px' }} gap={2} mt={4}>
            <Stack direction="column" gap={1}>
              <Typography fontSize={20} fontWeight={700}>
                Overall Rewards
              </Typography>
              <Controller
                name="referral_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP AWARDED FOR REFERRALS"
                    placeholder="Enter your referral amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="share_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP AWARDED FOR SHARING"
                    placeholder="Enter your sharing amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="profile_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP FOR COMPLATING PROFILE"
                    placeholder="Enter your profile xp"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction="column" sx={{ width: '400px' }} gap={2} mt={4}>
            <Stack direction="column" gap={1}>
              <Typography fontSize={20} fontWeight={700}>
                Mapping Rewards
              </Typography>
              <Controller
                name="m2e_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP REWARD FOR ADDING A RESTAURANT"
                    placeholder="Enter your restaurant amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="m2e_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP REWARD FOR ADDING A WMO"
                    placeholder="Enter your WMO amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Typography pt={3} fontSize={14} fontWeight={700}>
                RESTAURANT MAPPING REWARDS
              </Typography>
              <Controller
                name="m2e_image_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="RESTAURANT IMAGE"
                    placeholder="Enter your image amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="m2e_details_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="RESTAURANT CONTRACT"
                    placeholder="Enter your details amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="m2e_phone_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="RESTAURANT PERSON IN CHARGE DETAILS"
                    placeholder="Enter your phone amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="m2e_uco_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="RESTAURANT WASTE PRODUCTION"
                    placeholder="Enter your UCO amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="m2e_wmo_xp"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="RESTAURANT WMO INFORMATION"
                    placeholder="Enter your WMO amount"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack direction="column" sx={{ width: '400px' }} gap={2} mt={4}>
            <Stack direction="column" gap={1}>
              <Typography fontSize={20} fontWeight={700}>
                Reward Limits
              </Typography>
              <Controller
                name="weekly_limit_token"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="REWARD LIMIT FOR THIS WEEk"
                    placeholder="Week Limit Token"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
              <Typography fontSize={10} fontWeight={400}>
                {data?.remaining_weekly_limit_token.toLocaleString()} tokens
                remaining from this weeks limit
              </Typography>

              <Typography fontSize={14} fontWeight={400}>
                At{' '}
                <span style={{ fontWeight: 700 }}>
                  {moment(data?.next_reset_weekly_limit_token_date_time).format(
                    'DD/MM/YYYY HH:mm',
                  )}
                </span>{' '}
                this limit will reset to 0 to ensure that newly deposited tokens
                into the rewards pool are not drained, the admin should come
                back and reset the limit.
              </Typography>
            </Stack>

            <Stack direction="column" gap={1}>
              <Controller
                name="xp_for_nft_mint"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    title="XP FOR NFT MINT"
                    placeholder="XP FOR NFT MINT"
                    value={value}
                    onChange={(e) => onChange(e)}
                    required={true}
                  />
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

// moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');

export default RewardsPage;
