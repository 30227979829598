import React, { FC, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from '@mui/joy';

import DataTable from '../../../components/datatable/DataTable';
import { IUserMappingModel } from '../../../apis/types';
import { apiGetMappingUser } from '../../../apis/users';

interface IMappingTableProps {
  id: string | undefined;
}

const MappingTable: FC<IMappingTableProps> = ({ id }) => {
  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserMappingModel>>();
  }, []);

  const { data, isLoading, isPlaceholderData } = useQuery({
    queryKey: ['mapping'],
    queryFn: async () => {
      if (id) {
        const mapping = await apiGetMappingUser(id);
        return {
          rows: mapping.data,
          total: 0,
        };
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(() => {
    return [
      helper.accessor('location', {
        header: 'Location Name',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('date', {
        header: 'Date/Time',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('earned_xp', {
        header: 'Awarded XP',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.display({
        header: 'Transaction',
        cell: (info) =>
          info.row.original.transaction_link ? (
            <Link
              href={info.row.original.transaction_link}
              color="success"
              target="_blank"
            >
              View
            </Link>
          ) : (
            <></>
          ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
  });
  return (
    <DataTable
      table={table}
      loading={isLoading || isPlaceholderData}
      pagination={false}
    />
  );
};

export default MappingTable;
