import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IRewardConstantsModel } from './types';

export const apiGetRewardConstants = async () => {
  try {
    const response = await axios.get('/api/admin/v1/common/constants');
    return response.data.data;
  } catch (e) {
    return {};
  }
};

export const apiPutRewardConstants = async (data: IRewardConstantsModel) => {
  try {
    await axios.put('/api/admin/v1/common/constants', data);
    enqueueSnackbar('Successfully reward constants updated', {
      variant: 'success',
    });
  } catch (e) {
    enqueueSnackbar('Reward constants updated', { variant: 'error' });
  }
};
