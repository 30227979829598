import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IChapterModel } from './types';

export const apiGetChapters = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number;
  pageSize: number;
}) => {
  try {
    const response = await axios.post<{
      total?: number;
      data: IChapterModel[];
    }>('/api/admin/v1/l2e/chapters', {
      page: pageIndex,
      limit: pageSize,
    });
    return {
      rows: response.data.data,
      total: response.data.total,
    };
  } catch (e) {
    enqueueSnackbar('Failed to load chapters data', { variant: 'error' });
    return {
      rows: [],
      total: 0,
    };
  }
};

export const apiGetAllChapters = async () => {
  try {
    const response = await axios.post('/api/admin/v1/l2e/chapters');
    return response.data.data;
  } catch (e) {
    return [];
  }
};

export const apiGetChapter = async (id: string) => {
  try {
    const response = await axios.get(`/api/admin/v1/l2e/chapter/${id}`);
    return await response.data;
  } catch (e) {
    enqueueSnackbar('Failed to load chapter data', { variant: 'error' });
    return {};
  }
};

export const apiPostChapter = async (title: string) => {
  try {
    const response = await axios.post('/api/admin/v1/l2e/chapter', {
      title: title,
    });
    return response.data as Partial<IChapterModel>;
  } catch (e) {
    enqueueSnackbar('Failed to load chapters data', { variant: 'error' });
    return [];
  }
};

export const apiPutChapter = async (id: string, chapter: IChapterModel) => {
  try {
    await axios.put(`/api/admin/v1/l2e/chapter/${id}`, {
      title: chapter.title,
      locked: chapter.locked,
      open_m2e: chapter.open_m2e || false,
    });
    enqueueSnackbar('Successfully updated the selected challenge', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update the selected chapter', {
      variant: 'error',
    });
    return false;
  }
};

export const apiDeleteChapter = async (id: string) => {
  try {
    await axios.delete(`/api/admin/v1/l2e/chapter/${id}`);
    enqueueSnackbar('Successfully deleted the selected chapter', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to delete the selected chapter', {
      variant: 'error',
    });
    return false;
  }
};

export const apiReorderChapter = async (id: string, order: number) => {
  try {
    await axios.put(`/api/admin/v1/l2e/chapter/${id}/reorder`, {
      order: order,
    });
    // enqueueSnackbar('Successfully deleted the selected chapter', {
    //   variant: 'success',
    // });
    return true;
  } catch (e) {
    // enqueueSnackbar('Failed to delete the selected chapter', {
    //   variant: 'error',
    // });
    return false;
  }
};
