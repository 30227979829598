import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Select,
  Option,
  Typography,
} from '@mui/joy';
import React, { FC, useEffect, useState } from 'react';
import { IChapterModel } from '../../apis/types';
import CustomInput from '../../components/forms/CustomInput';

interface IEditChallengeDialogProps {
  open?: boolean;
  challenge?: {
    title: string;
    locked: string;
  };
  onPositive: (chapter: IChapterModel) => void;
  onNegative: () => void;
  onClose: () => void;
}

const EditChallengeDialog: FC<IEditChallengeDialogProps> = ({
  open = false,
  challenge = { title: '', locked: '' },
  onPositive,
  onNegative,
  onClose,
}) => {
  const [title, setTitle] = useState<string>('');
  const [locked, setLocked] = useState<string | null>('');
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    if (challenge) {
      setTitle(challenge?.title || '');
      setLocked(challenge?.locked || '');
    }
  }, [challenge]);
  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth={320} maxWidth={480}>
        <ModalClose />
        <DialogTitle>Challenge Edit</DialogTitle>
        <DialogContent>
          <CustomInput
            validation={validated}
            required
            title="Challenge Title"
            value={title || ''}
            placeholder="Input your challenge title"
            onChange={(e) => {
              setTitle(e);
            }}
          />
          <Typography mt={1}>Challenge Locked</Typography>
          <Select
            title="Challenge Type"
            value={locked || 'auto'}
            onChange={(e, newValue) => {
              setLocked(newValue);
            }}
          >
            <Option value="auto">Auto</Option>
            <Option value="locked">Locked</Option>
            <Option value="unlocked">Unlocked</Option>
          </Select>
        </DialogContent>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button variant="outlined" onClick={onNegative}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setValidated(true);
                if (title) {
                  onPositive({ title, locked } as IChapterModel);
                  setValidated(false);
                }
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
export default EditChallengeDialog;
