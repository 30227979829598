import axios from 'axios';
import { store } from '../redux/store';
import { logout } from '../redux/auth.slice';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
axios.interceptors.request.use((config) => {
  const token = store.getState().auth?.accessToken;
  if (token) {
    config.headers.set('x-access-token', token);
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error && error.response?.status == 401) {
      store.dispatch(logout());
      window.location.href = '/auth/signin';
    }
    return Promise.reject(error);
  },
);
