import React, { FC, useEffect, useState } from 'react';
import {
  Stack,
  Button,
  Typography,
  Box,
  Dropdown,
  MenuButton,
  Menu,
  MenuItem,
  Checkbox as JoyCheckbox,
  Textarea,
} from '@mui/joy';
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  apiGetPlaceDetail,
  apiValidateDetails,
  apiBlockAndReject,
  apiRejectMappedplace,
} from '../../../apis/places';
import { apiGetRewardConstants } from '../../../apis/rewards';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';

const schema = yup.object({
  image: yup.bool().oneOf([true, false]),
  waste_production: yup.bool().oneOf([true, false]),
  wmo_information: yup.bool().oneOf([true, false]),
  contact_detail: yup.bool().oneOf([true, false]),
  person_in_charge: yup.bool().oneOf([true, false]),
});

const PlaceDetailPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{
    id: string;
    status: string;
    detailsVerified: string;
  }>();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      image: true,
      waste_production: true,
      wmo_information: true,
      contact_detail: true,
      person_in_charge: true,
    },
    resolver: yupResolver(schema),
  });

  const checkboxValues = watch();

  const [isDisapproving, setIsDisapproving] = useState<boolean>(false);
  const [isRewarding, setIsRewarding] = useState<boolean>(false);
  const [totalXp, setTotalXp] = useState(0);
  const [modal, setModal] = useState<any>({
    open: false,
    data: {},
  });
  const [rejectModal, setRejectModal] = useState<any>({
    open: false,
    reason: '',
  });

  const { data, refetch } = useQuery({
    queryKey: ['place', id],
    queryFn: async () => {
      if (id) {
        const place = await apiGetPlaceDetail(id);
        return {
          ...place,
        };
      }
    },
  });

  const { data: rewardsData } = useQuery({
    queryKey: ['rewards'],
    queryFn: async () => {
      const response = await apiGetRewardConstants();
      setTotalXp(
        response.m2e_details_xp +
          response.m2e_image_xp +
          response.m2e_phone_xp +
          response.m2e_uco_xp +
          response.m2e_wmo_xp,
      );
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const handleDisapprove = async (reason: string) => {
    setIsDisapproving(true);
    await apiRejectMappedplace(id, reason);
    refetch();
    setIsDisapproving(false);
    setRejectModal({ ...rejectModal, open: false });
  };

  const handleDisapproveAndBlock = async (created_by: any) => {
    setIsDisapproving(true);
    await apiBlockAndReject(created_by);
    refetch();
    setIsDisapproving(false);
  };

  const handleReward = async (values: any) => {
    const data = {
      has_image: !!values.image,
      set_contact_info: !!values.contact_detail,
      set_charge_info: !!values.person_in_charge,
      set_waste_info: !!values.waste_production,
      set_wmo_info: !!values.wmo_information,
      add_to_crm: true,
    };

    setModal({ ...modal, open: true, data });
  };

  const handleRewardConfirmation = async () => {
    setModal({ ...modal, open: false });
    setIsRewarding(true);
    await apiValidateDetails(id, modal.data);
    refetch();
    setIsRewarding(false);
  };

  useEffect(() => {
    if (rewardsData) {
      const xp =
        (checkboxValues.image ? rewardsData.m2e_image_xp : 0) +
        (checkboxValues.waste_production ? rewardsData.m2e_uco_xp : 0) +
        (checkboxValues.wmo_information ? rewardsData.m2e_wmo_xp : 0) +
        (checkboxValues.contact_detail ? rewardsData.m2e_details_xp : 0) +
        (checkboxValues.person_in_charge ? rewardsData.m2e_phone_xp : 0);
      setTotalXp(xp);
    }
  }, [checkboxValues, rewardsData]);

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems="flex-start"
      >
        <Stack>
          <Stack
            direction={'row'}
            sx={{ width: '100%' }}
            alignItems={'center'}
            gap={4}
          >
            <Button size="sm" color="success" onClick={() => navigate(-1)}>
              Back
            </Button>
            <Typography level="h2">{data?.name ?? ''}</Typography>
          </Stack>

          <Stack direction={'column'} mt={2}>
            <Typography level="body-sm">
              <span style={{ color: '#36354680' }}>Mapped By: </span>
              {data?.created_username}
            </Typography>
            <Typography level="body-sm">
              <span style={{ color: '#36354680' }}>Total Submissions: </span>
              {data?.count_total_his_places}
            </Typography>
            <Typography level="body-sm">
              <span style={{ color: '#36354680' }}>Approved: </span>
              {data?.count_total_approved_his_places}
            </Typography>
            <Typography level="body-sm">
              <span style={{ color: '#36354680' }}>Rejected: </span>
              {data?.count_total_rejected_his_places}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="end" gap={4}>
          <Stack pr={5} direction="row" gap={2}>
            <Dropdown>
              <MenuButton loading={isDisapproving}>Actions</MenuButton>
              <Menu>
                <MenuItem
                  onClick={() => setRejectModal({ ...rejectModal, open: true })}
                >
                  Reject
                </MenuItem>
                <MenuItem
                  onClick={() => handleDisapproveAndBlock(data?.created_by)}
                >
                  Reject and Block
                </MenuItem>
              </Menu>
            </Dropdown>
          </Stack>
        </Stack>
      </Stack>

      <form onSubmit={handleSubmit(handleReward)}>
        <Card
          sx={{
            mt: 5,
            backgroundColor: '#FAFAF8',
          }}
        >
          <Grid container mt={4} spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack p={3} height={200}>
                <Typography
                  sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                >
                  Restaurant Details
                </Typography>
                <Stack pt={2} pl={3}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Place name:
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.name || ''}
                  </Typography>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Place address:
                  </Typography>
                  <Typography
                    width={200}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.address || ''}
                  </Typography>
                </Stack>
              </Stack>
              <Stack p={3} mt={3} height={200}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                  >
                    Contact Detail
                  </Typography>
                  {(!data?.verified || data?.verified === false) && (
                    <Controller
                      control={control}
                      name="contact_detail"
                      render={({ field }) => (
                        <FormControl>
                          <FormControlLabel
                            control={<Checkbox checked={field.value} />}
                            label={`${rewardsData?.m2e_details_xp || 0}XP`}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Stack>
                <Stack pt={2} pl={3}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Contact Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.contact_name || ''}
                  </Typography>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Spoken Language
                  </Typography>
                  <Typography
                    width={170}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.spoken_language}
                  </Typography>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Contact Email
                  </Typography>
                  <Typography
                    width={170}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.contact_email || ''}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack p={3} height={200}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                  >
                    Image
                  </Typography>
                  {(!data?.verified || data?.verified === false) && (
                    <Controller
                      control={control}
                      name="image"
                      render={({ field }) => {
                        return (
                          <FormControl>
                            <FormControlLabel
                              control={<Checkbox checked={field.value} />}
                              label={`${rewardsData?.m2e_image_xp || 0}XP`}
                              {...field}
                            />
                          </FormControl>
                        );
                      }}
                    />
                  )}
                </Stack>
                {data?.photo_url ? (
                  <Box pt={1}>
                    <img
                      src={data?.photo_url}
                      alt="image"
                      style={{ height: 160 }}
                    />
                  </Box>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    height="80%"
                  >
                    <Typography>No Image</Typography>
                  </Stack>
                )}
              </Stack>
              <Stack p={3} mt={3} height={200}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                  >
                    Person in Charge
                  </Typography>
                  {(!data?.verified || data?.verified === false) && (
                    <Controller
                      control={control}
                      name="person_in_charge"
                      render={({ field }) => (
                        <FormControl>
                          <FormControlLabel
                            control={<Checkbox checked={field.value} />}
                            label={`${rewardsData?.m2e_phone_xp || 0}XP`}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Stack>
                <Stack pt={2} pl={3}>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Contact Email
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.charge_email || ''}
                  </Typography>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Spoken Language
                  </Typography>
                  <Typography
                    width={170}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.spoken_language || ''}
                  </Typography>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Contact Name
                  </Typography>
                  <Typography
                    width={170}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    {data?.charge_name}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack p={3} height={100}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                  >
                    Waste Production
                  </Typography>
                  {(!data?.verified || data?.verified === false) && (
                    <Controller
                      control={control}
                      name="waste_production"
                      render={({ field }) => (
                        <FormControl>
                          <FormControlLabel
                            control={<Checkbox checked={field.value} />}
                            label={`${rewardsData?.m2e_uco_xp || 0}XP`}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Stack>
                <Stack>
                  <Typography
                    pt={2}
                    sx={{
                      fontSize: 12,
                      color: 'rgba(54, 53, 70, 05)',
                    }}
                  >
                    Collector Amount:
                  </Typography>
                  <Typography
                    width={170}
                    sx={{
                      fontSize: 13,
                      fontWeight: 700,
                      color: '#363546',
                    }}
                  >
                    100 KG
                  </Typography>
                </Stack>
              </Stack>
              <Stack p={3} mt={3} height={300}>
                <Stack direction="row" alignItems="center" gap={1.5}>
                  <Typography
                    sx={{ fontSize: 20, fontWeight: 700, color: '#363546' }}
                  >
                    WMO Information
                  </Typography>
                  {(!data?.verified || data?.verified === false) && (
                    <Controller
                      control={control}
                      name="wmo_information"
                      render={({ field }) => (
                        <FormControl>
                          <FormControlLabel
                            control={<Checkbox checked={field.value} />}
                            label={`${rewardsData?.m2e_wmo_xp || 0}XP`}
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                  )}
                </Stack>
                <Stack direction="column" gap={2}>
                  <Stack>
                    <Typography
                      pt={2}
                      sx={{
                        fontSize: 12,
                        color: 'rgba(54, 53, 70, 05)',
                      }}
                    >
                      Is the Waste Collected
                    </Typography>
                    <Typography
                      width={170}
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: '#363546',
                      }}
                    >
                      {data?.is_collected_by_wmo ? 'Yes' : 'No'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(54, 53, 70, 05)',
                      }}
                    >
                      Is it a Company
                    </Typography>
                    <Typography
                      width={170}
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: '#363546',
                      }}
                    >
                      {data?.is_company ? 'Yes' : 'No'}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(54, 53, 70, 05)',
                      }}
                    >
                      Collectors Name
                    </Typography>
                    <Typography
                      width={170}
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: '#363546',
                      }}
                    >
                      {data?.collector_name || ''}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(54, 53, 70, 05)',
                      }}
                    >
                      Collectors Email
                    </Typography>
                    <Typography
                      width={170}
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: '#363546',
                      }}
                    >
                      {data?.collector_email || ''}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: 12,
                        color: 'rgba(54, 53, 70, 05)',
                      }}
                    >
                      Collectors Phone
                    </Typography>
                    <Typography
                      width={170}
                      sx={{
                        fontSize: 13,
                        fontWeight: 700,
                        color: '#363546',
                      }}
                    ></Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Stack pb={5} pr={5} direction="row" gap={1} justifyContent="end">
            <Button
              variant="outlined"
              sx={{
                borderRadius: 40,
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            {(!data?.verified || data?.verified === false) && (
              <Button
                sx={{
                  borderRadius: 40,
                }}
                type="submit"
                loading={isRewarding}
                onClick={handleSubmit(handleReward)}
              >
                {`Reward (${totalXp}XP)`}
              </Button>
            )}
          </Stack>
        </Card>
      </form>
      <ConfirmationDialog
        open={modal.open}
        title="Reward User?"
        description={
          <Box>
            <Typography component="p">
              {`You are about to reward the user ${totalXp}XP for the details
              provided.`}
            </Typography>
            <Stack direction="row" alignItems="center" mt={2}>
              <JoyCheckbox
                label="Add to CRM?"
                checked={modal.data.add_to_crm}
                onChange={(event: any) =>
                  setModal({
                    ...modal,
                    data: { ...modal.data, add_to_crm: event.target.checked },
                  })
                }
              />
            </Stack>
          </Box>
        }
        onPositive={handleRewardConfirmation}
        onNegative={() => setModal({ ...modal, open: false })}
      />

      <ConfirmationDialog
        open={rejectModal.open}
        title="Reject"
        description={
          <Box>
            <Typography component="p">
              You are about to reject this restaurant.
            </Typography>
            <Stack direction="row" alignItems="center" mt={2}>
              <Textarea
                sx={{ width: '100%' }}
                minRows={4}
                onChange={(event: any) =>
                  setRejectModal({ ...rejectModal, reason: event.target.value })
                }
              />
            </Stack>
          </Box>
        }
        onPositive={() => handleDisapprove(rejectModal.reason)}
        onNegative={() => setRejectModal({ ...rejectModal, open: false })}
      />
    </>
  );
};

export default PlaceDetailPage;
