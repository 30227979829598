import { Components, Theme } from '@mui/joy';
import { colors } from '../theme';

export const JoyInput: Components<Theme>['JoyInput'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      backgroundColor: colors.white,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#EEECE7',
      color: colors.raisinBlack,
      lineHeight: '20px',
      boxShadow: 'none',

      ...(ownerState.size === 'lg' && {
        height: 48,
        fontSize: 14,
      }),
      ...(ownerState.size === 'md' && {
        height: 40,
        fontSize: 14,
        padding: '0 16px',
      }),
      ...(ownerState.size === 'sm' && {
        height: 32,
        fontSize: 14,
      }),

      '&:hover': {
        borderColor: '#CCCAC5',
      },
      '&:focus, &.Mui-focused': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '&:active': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '&::before': {
        content: '""',
        display: 'none',
      },

      '.Mui-error &': {
        backgroundColor: '#D64C5612',
      },
      '&.Mui-error': {
        backgroundColor: '#D64C5612',
      },

      '& .MuiInput-endDecorator, &.MuiInput-startDecorator': {
        color: '#7783A1',
        ...(ownerState.size === 'md' && {
          '& svg': {
            width: '16px !important',
            height: '16px !important',
          },
        }),
      },
    }),
  },
};

export const JoyTextArea: Components<Theme>['JoyTextarea'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      backgroundColor: colors.white,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#EEECE7',
      color: colors.raisinBlack,
      lineHeight: '20px',
      boxShadow: 'none',

      ...(ownerState.size === 'lg' && {
        fontSize: 14,
      }),
      ...(ownerState.size === 'md' && {
        fontSize: 14,
      }),
      ...(ownerState.size === 'sm' && {
        fontSize: 14,
      }),

      '&:hover': {
        borderColor: '#CCCAC5',
      },
      '&:focus, &.Mui-focused': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '&:active': {
        borderWidth: 1,
        borderColor: '#9F9E9E',
        outlineColor: `${colors.icterine}80`,
        outlineWidth: 2,
        outlineStyle: 'solid',
      },
      '.Mui-error &': {
        backgroundColor: '#D64C5612',
      },
      '&.Mui-error': {
        backgroundColor: '#D64C5612',
      },
    }),
  },
};

export const JoyFormLabel: Components<Theme>['JoyFormLabel'] = {
  styleOverrides: {
    root: () => ({
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1,
      letterSpacing: '0.36px',
      color: colors.raisinBlack,
      marginBottom: 8,

      '.Mui-error &': {
        color: colors.red,
      },
      '&.Mui-error': {
        color: colors.red,
      },
    }),
  },
};

export const JoyFormHelperText: Components<Theme>['JoyFormHelperText'] = {
  styleOverrides: {
    root: () => ({
      fontSize: 12,
      fontWeight: 400,
      marginTop: 6,

      '.Mui-error &': {
        color: colors.red,
      },
      '&.Mui-error': {
        color: colors.red,
      },
    }),
  },
};
