import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

export const apiUploadImage = async (files: any) => {
  try {
    const response = await axios.post('/api/common/v1/file/upload', files);
    return response.data;
  } catch (e) {
    enqueueSnackbar('Failed to upload image data', { variant: 'error' });
    return false;
  }
};
