import React, { FC, useMemo } from 'react';
import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Link } from '@mui/joy';
import moment from 'moment';

import { IUserLessonModel } from '../../../apis/types';
import { apiGetLessonsUser } from '../../../apis/users';
import DataTable from '../../../components/datatable/DataTable';

interface ILessonsTableProps {
  id: string | undefined;
}

const LessonsTable: FC<ILessonsTableProps> = ({ id }) => {
  const helper = useMemo(() => {
    return createColumnHelper<Partial<IUserLessonModel>>();
  }, []);

  const { data, isLoading, isPlaceholderData } = useQuery({
    queryKey: ['lessons'],
    queryFn: async () => {
      if (id) {
        const data = await apiGetLessonsUser(id);
        const lessons: any[] = [];
        data.data.forEach((item: any) => {
          item.lessons.forEach((lesson: IUserLessonModel) => {
            lessons.push({
              chapter: item.title,
              title: lesson.title,
              earned_xp: lesson.earned_xp,
              completed_date: lesson.completed_date,
              progress: lesson.progress,
              transaction_link: lesson.transaction_link,
            });
          });
        });
        return {
          rows: lessons,
          total: 0,
        };
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(() => {
    return [
      helper.accessor('chapter', {
        header: 'Challenge Title',
        cell: (info) => info.getValue(),
        size: 130,
        minSize: 130,
        maxSize: 130,
      }),
      helper.accessor('title', {
        header: 'Lesson Title',
        cell: (info) => info.getValue(),
        size: 400,
      }),
      helper.accessor('progress', {
        header: 'Completion Status',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
        size: 140,
        minSize: 140,
        maxSize: 140,
      }),
      helper.accessor('earned_xp', {
        header: 'XP',
        cell: (info) =>
          parseFloat(info.getValue()?.toLocaleString('en-US') ?? '0').toFixed(
            2,
          ),
      }),
      helper.accessor('completed_date', {
        header: 'Completed Date',
        cell: (info) => {
          if (info.getValue()) {
            return moment(info.getValue()).format('HH:mm:ss - DD/MM/YY');
          } else {
            return null;
          }
        },
        size: 200,
        minSize: 200,
        maxSize: 200,
      }),
      helper.display({
        header: 'Transaction',
        cell: (info) =>
          info.row.original.transaction_link ? (
            <Link
              href={info.row.original.transaction_link}
              color="success"
              target="_blank"
            >
              View
            </Link>
          ) : (
            <></>
          ),
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: data?.rows ?? [],
    enableFilters: false,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
  });
  return (
    <DataTable
      table={table}
      loading={isLoading || isPlaceholderData}
      pagination={false}
    />
  );
};

export default LessonsTable;
