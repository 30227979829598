import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IUserState {
  pageIndex: number | null;
  pageSize: number | null;
  search?: string | null;
  type?: string | null;
}

const initialState: IUserState = {
  pageIndex: null,
  pageSize: null,
  search: null,
  type: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState as IUserState,
  reducers: {
    setUserState(state, action: PayloadAction<IUserState>) {
      return action.payload;
    },
    resetUserState() {
      return initialState;
    },
  },
});

export const { setUserState, resetUserState } = userSlice.actions;
