import React, { FC, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  Box,
  Grid,
  ToggleButtonGroup,
  Button,
  Select,
  Option,
} from '@mui/joy';
import { LineChart } from '@mui/x-charts/LineChart';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from '@tanstack/react-table';
import { PieChartIcon } from 'lucide-react';

import StatCard from '../../components/widgets/StatCard';
import DataTable from '../../components/datatable/DataTable';
import {
  apiGetTotalInfo,
  apiGetEarnedXp,
  apiGetChaptersCompletion,
  apiGetActiveUsers,
  apiGetWalletsInfo,
  apiGetEarnedXpByScammers,
} from '../../apis/dashboard';
import { IActiveUserModel, IChapterCompletion } from '../../apis/types';

const DashboardPage: FC = () => {
  const { network } = useSelector((state: any) => state.network);
  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });
  const today = new Date();

  const [month, setMonth] = useState<number>(today.getMonth() + 1);
  const [year, setYear] = useState<number>(today.getFullYear());
  const [activeUserType, setActiveUserType] = useState<string | null>('day');

  const { data: unredeemed, isLoading: unredeemedLoading } = useQuery({
    queryKey: ['unredeemed'],
    queryFn: async () => {
      const unredeemed = await apiGetEarnedXp();

      return unredeemed;
    },
    placeholderData: keepPreviousData,
  });

  const { data: totalVechain, isLoading: totalVechainLoading } = useQuery({
    queryKey: ['totalVechain'],
    queryFn: async () => {
      const total = await apiGetTotalInfo('vechain');
      return total;
    },
    placeholderData: keepPreviousData,
  });

  const { data: totalXdc, isLoading: totalXdcLoading } = useQuery({
    queryKey: ['totalXdc'],
    queryFn: async () => {
      const total = await apiGetTotalInfo('xdc');
      return total;
    },
    placeholderData: keepPreviousData,
  });

  const { data: totalICP, isLoading: totalICPLoading } = useQuery({
    queryKey: ['totalICP'],
    queryFn: async () => {
      const total = await apiGetTotalInfo('nfid');
      return total;
    },
    placeholderData: keepPreviousData,
  });

  const { data: EarnedXpByScammers } = useQuery({
    queryKey: ['earnedXpByScammers'],
    queryFn: async () => {
      const response = await apiGetEarnedXpByScammers();
      return response;
    },
    placeholderData: keepPreviousData,
  });

  const { data: walletsVechain, isLoading: walletsVechainLoading } = useQuery({
    queryKey: ['walletVechain'],
    queryFn: async () => {
      const wallets = await apiGetWalletsInfo('vechain');
      return wallets;
    },
    placeholderData: keepPreviousData,
  });

  const { data: chaptersCompletion, isLoading: chaptersCompletionLoading } =
    useQuery({
      queryKey: ['chaptersCompletion'],
      queryFn: async () => {
        const chaptersCompletion = await apiGetChaptersCompletion();
        return chaptersCompletion.sort((a: any, b: any) => b.order - a.order);
      },
      placeholderData: keepPreviousData,
    });

  const { data: activeUsersData, isLoading: activeUsersLoading } = useQuery({
    queryKey: ['activeUsers', activeUserType, year, month],
    queryFn: async () => {
      const activeUsers = await apiGetActiveUsers(activeUserType || 'day');

      const activeUsersData: { data: number[]; xAxis: string[] } = {
        data: [],
        xAxis: [],
      };

      if (activeUserType === 'day') {
        activeUsers
          .sort(
            (a: IActiveUserModel, b: IActiveUserModel) =>
              Number(a.day) - Number(b.day),
          )
          .filter(
            (user: IActiveUserModel) =>
              Number(user.year) === year && Number(user.month) === month,
          )
          .forEach((user: { total: number; day: string }) => {
            activeUsersData.xAxis.push(user.day);
            activeUsersData.data.push(user.total);
          });
      } else if (activeUserType === 'month') {
        activeUsers
          .sort(
            (a: IActiveUserModel, b: IActiveUserModel) =>
              Number(a.month) - Number(b.month),
          )
          .filter((user: IActiveUserModel) => Number(user.year) === year)
          .forEach((user: { total: number; month: string }) => {
            activeUsersData.xAxis.push(user.month);
            activeUsersData.data.push(user.total);
          });
      } else if (activeUserType === 'year') {
        activeUsers
          .sort(
            (a: IActiveUserModel, b: IActiveUserModel) =>
              Number(a.year) - Number(b.year),
          )
          .forEach((user: { total: number; year: string }) => {
            activeUsersData.xAxis.push(user.year);
            activeUsersData.data.push(user.total);
          });
      }

      return {
        ...activeUsersData,
      };
    },
  });

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IChapterCompletion>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),

      helper.accessor('title', {
        header: 'Title',
        cell: (info) => info.getValue(),
      }),

      helper.accessor('percentage', {
        header: 'Percentage',
        cell: (info) => Number(info.getValue()).toFixed(2) + '%',
      }),
    ];
  }, [helper]);

  const table = useReactTable({
    data: chaptersCompletion || [],
    columns,
    enableFilters: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <PieChartIcon />
          <Typography level="h3">Dashboard</Typography>
        </Stack>
      </Stack>

      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Users"
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              value={
                network === 'XDC'
                  ? totalXdc?.total_users ?? 0
                  : network === 'ICP'
                    ? totalICP?.total_users ?? 0
                    : totalVechain?.total_users ?? 0
              }
              variant="dark"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Chapters"
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              value={
                network === 'XDC'
                  ? totalXdc?.total_chapters ?? 0
                  : network === 'ICP'
                    ? totalICP?.total_chapters ?? 0
                    : totalVechain?.total_chapters ?? 0
              }
              variant="dark-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Lessons"
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              value={
                network === 'XDC'
                  ? totalXdc?.total_lessons ?? 0
                  : network === 'ICP'
                    ? totalICP?.total_lessons ?? 0
                    : totalVechain?.total_lessons ?? 0
              }
              variant="light-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              title="UNREDEEMED Xp"
              loading={unredeemedLoading}
              value={new Intl.NumberFormat('en-US').format(unredeemed) ?? 0}
              variant="dark"
            />
          </Grid>
          <Grid>
            <StatCard
              title="Treasury Balance"
              loading={walletsVechainLoading}
              value={walletsVechain?.treasury_wallet_balance ?? 0}
              variant="light"
              unit={'B3TR'}
            />
          </Grid>
          <Grid>
            <StatCard
              title="Total Paid"
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              value={
                network === 'XDC'
                  ? String(
                      new Intl.NumberFormat('en-US').format(
                        totalXdc?.total_paid ?? 0,
                      ),
                    )
                  : network === 'ICP'
                    ? String(
                        new Intl.NumberFormat('en-US').format(
                          totalICP?.total_paid ?? 0,
                        ),
                      )
                    : String(
                        new Intl.NumberFormat('en-US').format(
                          totalVechain?.total_paid ?? 0,
                        ),
                      )
              }
              variant="dark"
              unit={'B3TR'}
            />
          </Grid>
          <Grid>
            <StatCard
              title="GAS Balance"
              loading={walletsVechainLoading}
              value={walletsVechain?.gas_wallet_balance ?? 0}
              variant="light-blue"
              unit={'VTHO'}
            />
          </Grid>
          <Grid>
            <StatCard
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              title="Total Transactions"
              value={
                network === 'XDC'
                  ? totalXdc?.total_transactions ?? 0
                  : network === 'ICP'
                    ? totalICP?.total_transactions ?? 0
                    : totalVechain?.total_transactions ?? 0
              }
              variant="light"
            />
          </Grid>
          <Grid>
            <StatCard
              loading={
                totalVechainLoading || totalXdcLoading || totalICPLoading
              }
              title="Total Scammers/Bots"
              value={
                network === 'XDC'
                  ? totalXdc?.total_scammers ?? 0
                  : network === 'ICP'
                    ? totalICP?.total_scammers ?? 0
                    : totalVechain?.total_scammers ?? 0
              }
              variant="dark-blue"
            />
          </Grid>
          <Grid>
            <StatCard
              loading={totalVechainLoading}
              title="Total XP from Scammers"
              value={EarnedXpByScammers ?? 0}
              variant="dark"
              unit="XP"
            />
          </Grid>
        </Grid>
      </Box>

      <Stack
        direction={isSm ? 'column' : 'row'}
        justifyContent="space-between"
        spacing={4}
        mt={4}
      >
        <Box flex={1} pt={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <ToggleButtonGroup
              value={activeUserType}
              onChange={(event, newValue) => {
                setActiveUserType(newValue);
              }}
            >
              <Button value="day">Day</Button>
              <Button value="month">Month</Button>
              <Button value="year">Year</Button>
            </ToggleButtonGroup>

            {activeUserType !== 'year' && (
              <Select
                value={year}
                onChange={(e, newValue) => {
                  setYear(Number(newValue));
                }}
              >
                {Array.from({ length: year - 2023 }).map((_, index) => (
                  <Option key={index} value={index + 2024}>
                    {index + 2024}
                  </Option>
                ))}
              </Select>
            )}
            {activeUserType === 'day' && (
              <Select
                value={month}
                onChange={(e, newValue) => {
                  setMonth(Number(newValue));
                }}
              >
                {Array.from({ length: 12 }).map((_, index) => (
                  <Option key={index} value={index + 1}>
                    {index + 1}
                  </Option>
                ))}
              </Select>
            )}
          </Stack>
          <LineChart
            xAxis={[
              {
                scaleType: 'time',
                data: activeUsersData?.xAxis ?? [],
                valueFormatter: (value: string) =>
                  new Intl.NumberFormat()
                    .format(Number(value))
                    .replace(',', ''),
              },
            ]}
            series={[
              {
                data: activeUsersData?.data ?? [],
              },
            ]}
            width={800}
            height={300}
            loading={activeUsersLoading}
          />
        </Box>
      </Stack>
      <Box flex={2} gap={1}>
        <Typography level="h4">Chapters Completion</Typography>
        <DataTable
          table={table}
          loading={chaptersCompletionLoading}
          pagination={false}
        />
      </Box>
    </>
  );
};

export default DashboardPage;
