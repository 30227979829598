import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { IRewardInfoModel } from './types';

export const apiGetVoters = async ({
  round,
  page,
  limit,
  sortBy,
  sortOrder,
}: {
  round: string;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
}) => {
  try {
    const response = await axios.post(
      `/api/admin/v1/common/raffle/round/${round}/voters`,
      {
        page,
        limit,
        ...(sortBy &&
          sortOrder && {
            sort: [{ field: sortBy, order: sortOrder }],
          }),
      },
    );
    return response.data.data;
  } catch (e) {
    enqueueSnackbar('Failed to load raffle voters data', { variant: 'error' });
    return {};
  }
};

export const apiGetRounds = async ({
  page,
  limit,
}: {
  page: number;
  limit: number;
}) => {
  try {
    const response = await axios.post('/api/admin/v1/common/raffle/rounds', {
      page,
      limit,
    });
    return response.data.data;
  } catch (e) {
    enqueueSnackbar('Failed to load raffle rounds data', { variant: 'error' });
    return {};
  }
};

export const apiGetRewardInfo = async () => {
  try {
    const response = await axios.get('/api/admin/v1/common/raffle/rewards');
    return response.data.data;
  } catch (e) {
    enqueueSnackbar('Failed to load raffle reward info', { variant: 'error' });
    return {};
  }
};

export const apiUpdateRewardInfo = async (data: IRewardInfoModel) => {
  try {
    await axios.put('/api/admin/v1/common/raffle/rewards', {
      ...data,
    });
    enqueueSnackbar('Successfully updated the reward info', {
      variant: 'success',
    });
    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update reward info', {
      variant: 'error',
    });
    return {};
  }
};
