import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography,
} from '@mui/joy';
import {
  EditIcon,
  SearchIcon,
  SlidersVerticalIcon,
  StoreIcon,
  TrashIcon,
} from 'lucide-react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import StatCard from '../../components/widgets/StatCard';
import DataTable from '../../components/datatable/DataTable';
import { Spinner } from '../../components/indicators/Spinner';
import OrganizationStatusChip from '../../components/chips/OrganizationStatusChip';
import ConfirmationDialog from '../../components/dialogs/ConfirmationDialog';
import {
  apiDeleteOrganization,
  apiGetOrganizations,
  apiUpdateOrgStatus,
} from '../../apis/organizations';
import { getSortingStateFromQuery } from '../../components/functions';
import { IOrganizationModel } from '../../apis/types';

const schema = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    status: yup.string().oneOf(['active', 'blocked']).required(),
  })
  .required();

interface IStatusChangeData {
  id: string;
  name: string;
  status: 'active' | 'blocked';
}

const OrganizationsPage: FC = () => {
  const [params, setParams] = useSearchParams();

  const [keyword, setKeyword] = useState('');
  const [stats, setStats] = useState({
    total: 0,
  });
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(params.get('pageIndex'))
      ? Number(params.get('pageIndex')) - 1
      : 0,
    pageSize: Number(params.get('pageSize')) || 10,
  });
  const [sorting, setSorting] = useState<SortingState>(
    getSortingStateFromQuery(params, ['_id', 'name', 'owner', 'location']),
  );
  const { data, isLoading, isPlaceholderData, refetch } = useQuery({
    queryKey: [
      'organizations',
      params.get('keyword') ?? '',
      params.get('pageIndex'),
      params.get('pageSize'),
      params.get('sortBy'),
    ],
    queryFn: async () => {
      const pageIndex = Number(params.get('pageIndex')) || 1;
      const pageSize = Number(params.get('pageSize')) || 10;

      let sortBy = '';
      let sortOrder = '';
      if (sorting.length > 0) {
        sortBy = sorting[0].id;
        sortOrder = sorting[0].desc ? 'desc' : 'asc';
      }

      return await apiGetOrganizations({
        keyword: params.get('keyword'),
        pageIndex,
        pageSize,
        sortBy,
        sortOrder,
      });
    },
    placeholderData: keepPreviousData,
  });

  const [deletingId, setDeletingId] = useState('');
  const [updatingId, setUpdatingId] = useState('');
  const [modals, setModals] = useState({
    status: false,
    delete: {
      show: false,
      id: '',
    },
  });
  const { control, handleSubmit, setValue } = useForm<IStatusChangeData>({
    defaultValues: {
      id: '',
      name: '',
      status: 'active',
    },
    resolver: yupResolver(schema),
  });

  const handleDeleteOrganization = async (id: string) => {
    if (deletingId || !id) {
      return;
    }

    setModals((values) => ({
      ...values,
      delete: {
        id: '',
        show: false,
      },
    }));

    setDeletingId(id);
    if (await apiDeleteOrganization(id)) {
      await refetch();
    }
    setDeletingId('');
  };

  const handleUpdateOrgStatus = async (values: IStatusChangeData) => {
    if (updatingId) {
      return;
    }
    handleToggleStatusModal();
    setUpdatingId(values.id);
    if (await apiUpdateOrgStatus(values.id, values.status)) {
      await refetch();
    }
    setUpdatingId('');
  };

  const handleToggleStatusModal = (values?: IStatusChangeData) => {
    setModals((values) => ({
      ...values,
      status: !values.status,
    }));
    if (values) {
      setValue('id', values.id);
      setValue('name', values.name);
      setValue('status', values.status);
    }
  };

  const handleSubmitFilters: React.FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (keyword) {
        newParams.set('keyword', keyword);
      } else {
        newParams.delete('keyword');
      }
      return newParams;
    });
  };

  const helper = useMemo(() => {
    return createColumnHelper<Partial<IOrganizationModel>>();
  }, []);

  const columns = useMemo(() => {
    return [
      helper.accessor('_id', {
        header: 'ID',
        cell: (info) => '#' + info.getValue()?.substr(-8),
        minSize: 80,
      }),
      helper.accessor('name', {
        header: 'Name',
        cell: (info) => info.getValue(),
      }),
      helper.accessor('owner', {
        header: 'Owner',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('location', {
        header: 'Location',
        cell: (info) => info.getValue(),
        sortDescFirst: false,
        sortUndefined: 1,
      }),
      helper.accessor('status', {
        header: 'Status',
        cell: (info) => <OrganizationStatusChip status={info.getValue()} />,
        enableSorting: false,
      }),
      helper.display({
        header: 'Actions',
        cell: (info) => (
          <Stack direction="row">
            <IconButton size="sm" color="primary">
              <EditIcon size={16} />
            </IconButton>
            <IconButton
              size="sm"
              onClick={() =>
                handleToggleStatusModal({
                  id: info.row.original._id ?? '',
                  name: info.row.original.name ?? '',
                  status:
                    info.row.original.status === 'active'
                      ? 'active'
                      : 'blocked',
                })
              }
            >
              {updatingId === info.row.original._id ? (
                <Spinner size={16} />
              ) : (
                <SlidersVerticalIcon size={16} />
              )}
            </IconButton>
            <IconButton
              size="sm"
              color="danger"
              onClick={() =>
                setModals((values) => ({
                  ...values,
                  delete: {
                    show: true,
                    id: info.row.original._id ?? '',
                  },
                }))
              }
            >
              {deletingId === info.row.original._id ? (
                <Spinner size={16} />
              ) : (
                <TrashIcon size={16} />
              )}
            </IconButton>
          </Stack>
        ),
      }),
    ];
  }, [helper, deletingId, updatingId]);

  const table = useReactTable({
    data: data?.rows ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    rowCount: data?.total ?? 0,
    state: {
      pagination: pagination,
      sorting: sorting,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
  });

  useEffect(() => {
    if (data) {
      setStats({
        total: data.total,
      });
    } else {
      setStats({
        total: 0,
      });
    }
  }, [data]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      newParams.set('pageIndex', (pagination.pageIndex + 1).toString());
      newParams.set('pageSize', pagination.pageSize.toString());
      return newParams;
    });
  }, [pagination]);

  useEffect(() => {
    setParams((oldParams) => {
      const newParams = new URLSearchParams(oldParams);
      if (sorting.length === 0) {
        newParams.delete('sortBy');
      } else {
        newParams.set(
          'sortBy',
          sorting.map((s) => `${s.id},${s.desc ? 'desc' : 'asc'}`).join(','),
        );
      }
      return newParams;
    });
  }, [sorting]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1}>
          <StoreIcon />
          <Typography level="h3">Organizations</Typography>
        </Stack>
      </Stack>
      <Box mt={4}>
        <Grid container spacing={1}>
          <Grid>
            <StatCard
              title="Total Organizations"
              value={stats.total}
              variant="dark-blue"
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4} component="form" onSubmit={handleSubmitFilters}>
        <Input
          placeholder="Search organizations"
          endDecorator={<SearchIcon />}
          sx={{
            maxWidth: 320,
          }}
          value={keyword}
          onChange={(ev) => setKeyword(ev.currentTarget.value)}
        />
      </Box>
      <Box mt={4}>
        <DataTable table={table} loading={isLoading || isPlaceholderData} />
      </Box>
      <Modal open={modals.status} onClose={() => handleToggleStatusModal()}>
        <ModalDialog minWidth={320} maxWidth={480}>
          <ModalClose />
          <DialogTitle>Switch Organization Status</DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              component="form"
              onSubmit={handleSubmit(handleUpdateOrgStatus)}
            >
              <Controller control={control} name="id" render={() => <></>} />
              <Controller
                control={control}
                name="name"
                render={({ field: { value } }) => (
                  <FormControl required>
                    <FormLabel>Organization</FormLabel>
                    <Input value={value} readOnly />
                  </FormControl>
                )}
              />
              <Box mt={2}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <FormControl required>
                      <FormLabel>Status</FormLabel>
                      <Select
                        value={field.value}
                        onChange={(ev, value) => field.onChange(value)}
                      >
                        <Option value="active">Active</Option>
                        <Option value="blocked">Blocked</Option>
                      </Select>
                    </FormControl>
                  )}
                />
              </Box>
              <Box mt={2}>
                <Button fullWidth type="submit">
                  Confirm
                </Button>
              </Box>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
      <ConfirmationDialog
        open={modals.delete.show}
        title="Delete Organization"
        description="Are you sure you want to delete this organization? This action cannot be undone."
        onPositive={() => handleDeleteOrganization(modals.delete.id)}
        onNegative={() =>
          setModals((values) => ({
            ...values,
            delete: {
              show: false,
              id: '',
            },
          }))
        }
      />
    </>
  );
};

export default OrganizationsPage;
