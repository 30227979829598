import React from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import AuthLayout from '../components/layouts/AuthLayout';
import MainLayout from '../components/layouts/MainLayout';

import SignInPage from '../pages/auth/SignInPage';
import SignOutPage from '../pages/auth/SignOutPage';

import DashboardPage from '../pages/dashboard/DashboardPage';
import UsersPage from '../pages/users/UsersPage';
import OrganizationsPage from '../pages/organizations/OrganizationsPage';
import PrivacyPolicyPage from '../pages/settings/PrivacyPolicyPage';
import CookiePolicyPage from '../pages/settings/CookiePolicyPage';
import TermsPage from '../pages/settings/TermsPage';
import LearnToEarn from '../pages/learnToEarn/LearnToEarn';
import ChallengeManagement from '../pages/learnToEarn/ChallengeManagement';
import UserDetailPage from '../pages/users/userDetail/UserDetailPage';
import PlacesPage from '../pages/places/PlacesPage';
import RewardsPage from '../pages/rewards/RewardsPage';
import PlaceDetailPage from '../pages/places/placeDetail/PlaceDetailPage';
import WmoDetailPage from '../pages/places/placeDetail/WmoDetailPage';
import LevelsPage from '../pages/levels/LevelsPage';
import RafflePage from '../pages/raffle/RafflePage';
import VoterPage from '../pages/raffle/VoterPage/VoterPage';

export const routes = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'signin',
        element: <SignInPage />,
      },
      {
        path: 'signout',
        element: <SignOutPage />,
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        index: true,
        element: <Navigate to="/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      {
        path: 'users/',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <UsersPage />,
          },
          {
            path: 'detail/:id',
            element: <UserDetailPage />,
          },
        ],
      },
      {
        path: 'levels',
        element: <LevelsPage />,
      },
      {
        path: 'raffle/',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <RafflePage />,
          },
          {
            path: 'voter/:round',
            element: <VoterPage />,
          },
        ],
      },
      {
        path: 'organizations',
        element: <OrganizationsPage />,
      },
      {
        path: 'settings/privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'settings/cookie-policy',
        element: <CookiePolicyPage />,
      },
      {
        path: 'settings/terms',
        element: <TermsPage />,
      },
      {
        path: 'places',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <PlacesPage />,
          },
          {
            path: 'detail/:id',
            element: <PlaceDetailPage />,
          },
          {
            path: 'wmo-detail/:id/:name',
            element: <WmoDetailPage />,
          },
        ],
      },
      {
        path: 'rewards',
        element: <RewardsPage />,
      },
      {
        path: 'learn-to-earn/',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <LearnToEarn />,
          },
          {
            path: 'challenge/:id',
            element: <ChallengeManagement />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/dashboard" />,
  },
]);
