import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

export const apiGetLevels = async () => {
  try {
    const response = await axios.get('/api/admin/v1/common/levels');
    return response.data.data;
  } catch (e) {
    enqueueSnackbar('Failed to load levels data', { variant: 'error' });
    return {};
  }
};

export const apiUpdateLevelXP = async (id: string, value: number) => {
  try {
    await axios.put(`/api/admin/v1/common/level/${id}`, {
      xp_for_nft_mint: value,
    });

    enqueueSnackbar('Successfully updated level xp', {
      variant: 'success',
    });

    return true;
  } catch (e) {
    enqueueSnackbar('Failed to update level xp', { variant: 'error' });
    return false;
  }
};
