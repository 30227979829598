import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from '@mui/joy';
import 'react-quill/dist/quill.snow.css';
import { PlusCircleIcon, Trash2Icon } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';

import { IAnswerModel, IQuestionModel } from '../../apis/types';
import CustomInput from '../../components/forms/CustomInput';

interface ICreateQuestionsDialogProps {
  open?: boolean;
  onPositive: (
    id: string,
    title: string,
    xp: number,
    answers: IAnswerModel[],
  ) => void;
  onNegative: () => void;
  onClose: () => void;
  data?: IQuestionModel | null;
}

const CreateQuestionsDialog: FC<ICreateQuestionsDialogProps> = ({
  open = false,
  onPositive,
  onNegative,
  onClose,
  data,
}) => {
  const [title, setTitle] = useState<string>('');
  const [xp, setXP] = useState<number>(0);
  const [id, setId] = useState<string>('');
  const [options, setOptions] = useState<Array<IAnswerModel>>([
    {
      answer: '',
      checked: true,
    },
  ]);
  const [validated, setValidated] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setTitle(data.question);
      setXP(data.xp);
      setOptions(data.answers);
      setId(data._id || '');
    }
  }, [data]);

  const addAnswer = () => {
    setOptions([
      ...options,
      {
        answer: '',
        checked: false,
      },
    ]);
  };

  const removeAnswer = (index: number) => {
    const temp: IAnswerModel[] = [...options];
    temp.splice(index, 1);
    setOptions(temp);
  };

  const answerTextChange = (e: any, index: number) => {
    const temp: IAnswerModel[] = [...options];
    temp[index].answer = e.target.value;
    setOptions([...temp]);
  };

  const answerCheckChange = (index: number) => {
    const temp: IAnswerModel[] = [...options];
    temp[index].checked = !temp[index].checked;
    setOptions([...temp]);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog minWidth={600} maxWidth={600}>
        <ModalClose />
        <DialogTitle>Edit Question</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <CustomInput
              validation={validated}
              required
              title="Question"
              placeholder="Input your question"
              value={title}
              onChange={(e) => {
                setTitle(e);
              }}
            />
            <CustomInput
              validation={validated}
              required
              type="number"
              title="Question XP"
              value={xp}
              placeholder="Input your question xp"
              onChange={(e) => {
                setXP(Number(e));
              }}
            />
            <Stack flexDirection={'row'} gap={2} alignItems={'center'} mt={1}>
              <Typography>Answers</Typography>
              <IconButton onClick={addAnswer}>
                <PlusCircleIcon />
              </IconButton>
            </Stack>
            <Stack gap={1}>
              {options.map((option, index) => {
                return (
                  <Stack
                    key={index}
                    padding={1}
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={2}
                    sx={{ backgroundColor: 'gray500' }}
                  >
                    <Checkbox
                      checked={option.checked}
                      onChange={() => answerCheckChange(index)}
                    />
                    <Input
                      fullWidth
                      value={option.answer}
                      onChange={(e) => answerTextChange(e, index)}
                    />
                    <IconButton onClick={() => removeAnswer(index)}>
                      <Trash2Icon size={16} />
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </DialogContent>
        <Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Button variant="outlined" onClick={onNegative}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                setValidated(true);
                if (title && xp) {
                  const checkedOptions = options.filter(
                    (item) => item.checked === true,
                  );
                  const answeredOptions = options.filter(
                    (item) => !item.answer,
                  );
                  if (checkedOptions?.length === 0) {
                    enqueueSnackbar('Please select a correct answer', {
                      variant: 'error',
                    });
                  } else if (answeredOptions?.length > 0) {
                    enqueueSnackbar('Please input a question', {
                      variant: 'error',
                    });
                  } else {
                    onPositive(id, title, xp, options);
                    setOptions([
                      {
                        answer: '',
                        checked: true,
                      },
                    ]);
                  }

                  setValidated(false);
                }
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </ModalDialog>
    </Modal>
  );
};
export default CreateQuestionsDialog;
